import { text_mod_59, } from "../text/Text.js";
export const module59 = {
  title:
    "Буква й. Словосполучення йо",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_59,
    },
  ]
};
import { text_mod_94 } from "../text/Text.js";

export const module94 = {
  title: "Створюю зв’язну розповідь про ситуацію із життя «Мрії про літні канікули»",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_94,
    },
  ],
};

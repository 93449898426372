import { text_mod_122 } from "../text/Text.js";
export const module122 = {

  title: "Урок 122. Множення і ділення в межах 1000. Множення суми на число. Робота з діаграмою. Множення чисел виду 15 * 3. Множення числа на суму. Множення чисел виду 4 * 17. Розв'язання задач.",

  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_122,
    },
  ],
};

import { text_mod_91 } from "../text/Text.js";

export const module91 = {
  title: "Урок 91. Нумерація трицифрових чисел. Закріплення вивчених випадків додавання і віднімання у межах 1000. Дії з іменованими числами. Коло. Радіус і діаметр кола. Розв'язування задач.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_91,
    },
  ],
};

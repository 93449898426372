import { text_mod_3, } from "../text/Text.js";
export const module03 = {
  title:
    "Я допомагаю своїй родині",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_3,
    },
  ]
};
import { text_mod_48, } from "../text/Text.js";
export const module48 = {
  title:
    "Літера щ маленька",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_48,
    },
  ]
};
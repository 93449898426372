import { text_mod_155 } from "../text/Text.js";
export const module155 = {

  title: "Урок 149. Множення і ділення в межах 1000. Дії з іменованими числами на визначення часу. Додавання і віднімання іменованих чисел. Розв'язання задач про спільну роботу. Обчислення виразів з іменованими числами.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_155,
    },
  ],
};

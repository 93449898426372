import { text_mod_2, } from "../text/Text.js";
export const module02 = {
  title:
    "У гості до бабусі і дідуся",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_2,
    },
  ]
};
import {text_mod_12, } from "../text/Text.js";
export const module12 = {
  title:
    "Історія успіху ​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_12, },
    ]
  }

import { text_mod_23, } from "../text/Text.js";
export const module23 = {
  title:
    "Літера Г велика",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_23,
    },
  ]
};
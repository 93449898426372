export const text_mod_15 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICL40AAAAAAADp6Lsr230Nt1x4"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_16 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICL5EAAAAAAAJ4x-s_scozuTDI"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_17 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICL4kAAAAAAAD1vguYAmNFyP-M"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_18 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICL1kAAAAAAAbc7YGqkAJSoEgZgpNNV6FU"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_19 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICL4EAAAAAAABaoJzr2I-4Kdmw"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_20 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICL3kAAAAAAADOZDx-W5Lz25Fc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_21 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICL3EAAAAAAADWO_f-4FGqJdMI"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_22 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICL4UAAAAAAALfoWGWjBewr2jw"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_23 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICL3UAAAAAAAIF2s-eO2NQHkp0"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_24 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICL20AAAAAAABhwfy0akxHJX0c"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_25 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICL2kAAAAAAALp8kpzqStl3bvw"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_26 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICL2UAAAAAAAFcHD4btmjFJ-bM"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_27 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICL2EAAAAAAALtkZMQDDBgSGKM"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_28 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICL10AAAAAAAByIaKkcAHUc7Po"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_29 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICL1UAAAAAAAAQ9vZKEWWiNTsM"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_30 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICL1EAAAAAAAPb9fWvryLLyPZ4"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_32 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRR7vnYAigDRo14zt3VVRquAe5JJ1OLhrBFqeR3kUsflLg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_33 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTnXYPgKRubQ7izOrxzNVANAawskYNge4OYqIqQh3am_L8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_34 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQR36gNZia3bTb9WBTGrW7BUAWZ1ptDsrAOqBj3OafCMBN4"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_35 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQ9-BOrwM43QpUoodIBaEbVATVRV5YoiFZSSlmiNZThjDw"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_36 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSzRkXtfmKlQbdxCuGE_aIuAVawvge1onYJ-bUDYjv4_Xg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_37 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQhUDn3rIXZQrvawe00mWkWATCSHX7p0BAwO8mVyF85C88"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_38 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRT9339SGudQqwvSgcSbQhqAW-YmA3PXWyLRAaahNiYCwc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_39 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRISCS5NVZxTKGFQ9jBsCLvAUlGWLTl0Sc7w7M0-PNETO8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_40 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQureFkVHIST57_fMwEHzPqAWGZrRCFAm3Nd9KqHfGZFW8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_41 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRYJYMQN7KTT4T5TZk2W0d0AcrJAq0bOb3zsw64OD6JVsY"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_42 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQsxgNjQLfuS7yzsGmyixUxASEFvoZ8OuFh4osTyrlZAuQ"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_43 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQROqeDGSRBKSoZzJs0e_PyFASnQL8uaZaK6o55n3C4vRZk"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_44 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTCRiK6C7IARbzVY29XwDx2Ac_Yd0xB_ZybACT9p0HuhEo"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_45 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRd5dO3WOD1TYy0G41vlDfKAZDbcBAF2OgCpnUHwxr-y7o"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_46 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSL7V9Is2tIRbaGcQaBlXyyAZWy2k_1CYt_EhWNsYKN_MY"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_47 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQT8-k-HWEuXS5JFz6uGIkreAeIRpM8AKT6eRWgUyrYRWxM"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_48 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSLXa6yMx8zR5CPa_5z9MrDAbvw7fzbIbbGhgaAi5y8iOc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_49 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSbMLF9ddAESKHNgZbb_gQRARLO5CGnQa8yiF2hNCSgMO0"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_50 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQQoMURPShaQJGY0BCL6xnMActwksoXXcRHraB-JzdRdLU"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_51 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQzEhCVeKcsQrFBXtRuWs09AVh-_nit3_5Dgm8enIjo4gg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_52 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQR-GxZ7XNO8Rr-FtmJgrF18AdRNlG8giDsy3WHqJ5LEyd8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_53 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTci8Pzy0TVSK9NXvQj6JepAZgTenDKspDj0-o4KWOuHxg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_54 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSp4uQK9deeQJJh2dqwCnUBARy_0Yh9t1fe_pHaRJbS9QA"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_55 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQp97aFz6bgSK_no7rwNH1sAfoq3Qd1djiOm8_57sRnRO8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_56 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTAz0HwzE6qTo9FLq4W-UFoAaCYgYNXkPL7J1QFQYMsRVk"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_57 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQX7G2R50sSTY9AkZdKscVWAZZTK6EgKrz7Sm6RI3I39p4"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_58 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTEDlmD_cBETpgvdZ2CLR2CAZZunRSxmWgbJ0kD0Fgluyk"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_59 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTycumupOT-Q7cNAlQ8Z4TdAZMzFxQivoeWWyLhbHczZFY"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_60 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRd2EPsZRdhSJvj8yRkj_SkAXUZ75BlZPQHw0Iqc9MmoEg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_61 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRNkd0Dj21UQKA9jQ60EuQVAZVfpxt2HEuRPl1cmc5rlLg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_62 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRnbD1iG-CNT4LVRHWbvkW-AcKu9baJ4waYpqidSelmPdY"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_63 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSsqHIorJruRYqvja-GO2bkAVHtAWv0NqSl1yD-LftkM4g"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_64 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQStKjogWp72SqVUZ9VvOedAAcxpWMYaMEVstagYkRlphSc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_65 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQT7ra1H75PLRpeG3OEvrcUjASX35agSgFLuy4a_9ErnDco"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_66 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQS-Do8e9js3T6-6-28qaObzARxOhX1nG4brRE_t_TD98F0"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_67 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQ6rfGsi6jvSp97hBAx6tcPAX2fxB8KJhipn_xag9uqS8g"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_68 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQF0NclNu_SSrAlkCYJGxF7AaKHArEe4g1ZDu8I0PuFHN8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_69 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSYH11w8_29TpCWlJuFEQmlAQtWSdEf2pjTR0LIqsGOIOo"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_70 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRIYyY7oCDYQobOD-iiHRrIAWS-aIQGRQ2DC3oDnN-2Pfk"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_71 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTmTv1cFULEQYhggO4QbZ2wASRLNDKfy5DCu0KK-RY-U5g"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;
export const text_mod_72 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQR4nh90v02-RIetrlUu4a0SAT6RabAaEZMKlSt4t_T-JVY"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_73 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQROrMcm64hBQIZ-nz8PbJk3AVgC_AC7G2Gpd50cBZTYjbE"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_74 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTs6kIuKnq2T4NIW-9SWTh_AXrvjHR6-qQlUeifxK1Q5Rs"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_75 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQ61DIrwaHeR4RlMN6YHF5LAVD48v9He6bF_sb9sBznrgI"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_76 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQRyzbySoHQTqWrhZug0PzLAUDSl5X-PYPMBefdXz_AW10"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_77 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSL--EIxm5wRpRjUNrv8JcWAZjFCD9gxn84XGGck4QtLFw"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_78 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQR89qjsdRT-QL2C20hRhTHCAeDH7HtwYchV0T76cS1R3TI"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_79 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQS5prikXDL_SaS5F5W3MlsaAcfQw7tap-1DNVYqwDjpncU"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_80 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTyw-3Ys7oiRaOgWzgcjT6PAfT8zmdMhakuMD9uP9XgTv0"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_81 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQS_dKj2wLLAR54_HZ9o38ffAX-FxfVdLg2Bh5teDCFwld4"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_82 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTwh-dy3HznSrIE8cBRt5blAcnAjAyloQtDYFYIvIKAeV0"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_83 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTgDgIptU0oQqYdwrvjulCXAZsYHaKVl1lp6iDQWUrFSns"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_84 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTbBIn7Kqb7T6nm_u0TVPcSATYQk97CsJ08IuNyxRynfXI"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_85 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSIf867TYsGQ7Uzo-_BthvQAR10aG0JL04HDRA88Joozrg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_86 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTvaNtQue1JQJMHCebjpNXcASlqEp09f2-oyCyi7WFfcwg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_87 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRlpA8KGAZtRoA3Ce3SjYnAAQqF2CcHX5Imn-Ejt6UK5fs"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_88 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTZNIXrSs9RSKG6wFbGoXh2AZu9csPZVpbprLDn-Euwm4A"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_89 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQScXcYkqpsmQJtRpRdAzMq6Af11lWkc9wQjuBH28TNWDOk"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_90 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRDS4unKdtCQ4eoiYuIWZHQAYZ83GzFz6qyoIvVcXcJ858"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_91 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQLIEcwxKg5RrfmXAeyRppTAY0LBRVAA3veOjTmgXgFqWo"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_92 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRdMUF8eiSuRIuhlGgSxJUSAW3UETBLacUW8rjYpNgYZMg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_93 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTCTXGOH6TaQZ4qXMk5DkCZAbbPTOruGvRBKUuUua_nqeA"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_94 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRH0BF4ju1bQJ6wHfC9EE_RAXGm-sulLWDzdLoWapqFaKA"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

import {text_mod_2, } from "../text/Text.js";
export const module02 = {
  title:
    "Родина, родина – це вся Україна",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_2, },
    ]
  }

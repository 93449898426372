import { text_mod_14, } from "../text/Text.js";
export const module14 = {
  title:
    "Літера Л",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_14,
    },
  ]
};
import { text_mod_81 } from "../text/Text.js";

export const module81 = {
  title: "Урок 81. Табличне множення і ділення. Обчислення виразів. Обернена задача. Додавання чисел виду 430 + 260. Складання і розв'язання обернених задач. Віднімання чисел виду 960 – 420.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_81,
    },
  ],
};

import { text_mod_92 } from "../text/Text.js";

export const module92 = {
  title: "Текст-есе",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_92,
    },
  ],
};

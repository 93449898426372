import { text_mod_65 } from "../text/Text.js";

export const module65 = {
  title: "Створюю письмове висловлення (розповідь) на цікаву тему, використовуючи частини тексту та малюнки",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_65,
    },
  ],
};

import { Component } from "react";
import { Container } from "react-bootstrap";

import LeftMobileMenu from "./LeftMobileMenu/LeftMobileMenu";

import logo from "../../images/logo-e.png";
import logoG from "../../images/logoG.jpg";
import iconHome from "../../images/home.png";
import instructionIcon from "./images/instruction.svg"

import "./Header.css";
import "./responsive.css";
import "./fontawesome.min.css";
import UserDropdown from "./UserDropdown/UserDropdown";
import SearchComponent from "../search/SearchComponent";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  handleShow = () => {
    this.setState({ showMenu: true });
  };

  handleClose = () => {
    this.setState({ showMenu: false });
  };



  render() {
    const { userName, userRoles } = this.props;
    const roleTranslations = {
      student: "учень",
      teacher: "вчитель",
      parent: "батьки"
    };

    if (userName) {
    }
    return (
      <>
        <header className="header header-bg">
          <Container className="purple-bg">
            <div className="top-bar">
              <p className="title"></p>

              <div className="ordering">
                <a href="/instruction">Інструкція<img
                  src={instructionIcon}
                  alt="User icon"
                  title="Інструкція користувача"
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    marginRight: "5px",
                  }} /></a>
                <div className="line"></div>
                {/* <a href="#">Служба підтримки</a> */}
                <UserDropdown userName={userName} userRoles={userRoles} />


              </div>
            </div>
            <div className="center-ber">
              <div className="logo">
                <a href="/home">
                  <img alt="logo" src={logo} style={{ width: "50%" }} />
                </a>
              </div>
              {/* <form> */}
              {/* <input type="text" name="Search" placeholder="Пошук..." /> */}
              <SearchComponent />
              {/* <button>
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button> */}
              {/* </form> */}

              {/* TODO: Uncomment after backend enabling */}
              {/* {userName ? <Logout userName={userName} /> : <LoginSignUpPanel />} */}


              {/* <div className="login-bar d-flex">
                <div>
                  <a
                    className="d-flex"
                    href="http://localhost/oauth2/authorization/google"
                  >
                    Увійти
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.364 11.636C14.3837 10.6558 13.217 9.93013 11.9439 9.49085C13.3074 8.55179 14.2031 6.9802 14.2031 5.20312C14.2031 2.33413 11.869 0 9 0C6.131 0 3.79688 2.33413 3.79688 5.20312C3.79688 6.9802 4.69262 8.55179 6.05609 9.49085C4.78308 9.93013 3.61631 10.6558 2.63605 11.636C0.936176 13.3359 0 15.596 0 18H1.40625C1.40625 13.8128 4.81279 10.4062 9 10.4062C13.1872 10.4062 16.5938 13.8128 16.5938 18H18C18 15.596 17.0638 13.3359 15.364 11.636ZM9 9C6.90641 9 5.20312 7.29675 5.20312 5.20312C5.20312 3.1095 6.90641 1.40625 9 1.40625C11.0936 1.40625 12.7969 3.1095 12.7969 5.20312C12.7969 7.29675 11.0936 9 9 9Z"
                        fill="#fff"
                      ></path>
                    </svg>
                  </a>
                </div>

                <div className="donation">
                  <a href="#" className="pr-cart">
                    Реєстрація
                  </a>
                </div>
              </div> */}
              <div className="mobile_h"> <UserDropdown userName={userName} userRoles={userRoles} /></div>

              <div className="bar-menu">
                <i className="fa-solid fa-bars" onClick={this.handleShow}></i>
                <LeftMobileMenu
                  show={this.state.showMenu}
                  handleClose={this.handleClose}
                />
              </div>

            </div>
          </Container>

          <div className="nav-bar">
            <div className="container">
              <nav className="navbar">
                <ul className="navbar-links">
                  <li className="navbar-dropdown">
                    <a href="/home">Головна</a>
                  </li>

                  <li className="navbar-dropdown menu-item-children">
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <i>
                        <img alt="home" src={iconHome} />
                      </i>
                      Клас
                    </a>
                    <div className="dropdown dropdown-additional">
                      <a href="/class/1">Клас 1</a>
                      <a href="/class/2">Клас 2</a>
                      <a href="/class/3">Клас 3</a>
                      <a href="/class/8">Клас 8</a>
                    </div>
                  </li>
                  {/* <li className="navbar-dropdown menu-item-children">
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      Теорія
                    </a>
                    <div className="dropdown dropdown-additional">
                      <a href="/theory/module1">Тестовий малюнок</a>
                      <a href="/theory/module2">Тестове відео</a>
                    </div>
                  </li> */}

                  <li className="navbar-dropdown menu-item-children">
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      Допомога
                    </a>
                    <div className="dropdown dropdown-additional">
                      <a href="/instruction">Інструкція</a>
                      <a href="/about">Про додаток</a>
                      <a href="/author_info">Про підручники</a>
                      {/* <a href="#">Служба підтримки</a> */}
                      <a href="https://www.gramota.kiev.ua" target="_blank">Сайт видавництва</a>
                    </div>
                  </li>
                </ul>

                <a className="phone" href="callto:+38093000000">
                  <i>
                    <svg
                      height="112"
                      viewBox="0 0 24 24"
                      width="112"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        clipRule="evenodd"
                        fill="rgb(255255,255)"
                        fillRule="evenodd"
                      >
                        <path d="m7 2.75c-.41421 0-.75.33579-.75.75v17c0 .4142.33579.75.75.75h10c.4142 0 .75-.3358.75-.75v-17c0-.41421-.3358-.75-.75-.75zm-2.25.75c0-1.24264 1.00736-2.25 2.25-2.25h10c1.2426 0 2.25 1.00736 2.25 2.25v17c0 1.2426-1.0074 2.25-2.25 2.25h-10c-1.24264 0-2.25-1.0074-2.25-2.25z"></path>
                        <path d="m10.25 5c0-.41421.3358-.75.75-.75h2c.4142 0 .75.33579.75.75s-.3358.75-.75.75h-2c-.4142 0-.75-.33579-.75-.75z"></path>
                        <path d="m9.25 19c0-.4142.33579-.75.75-.75h4c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-4c-.41421 0-.75-.3358-.75-.75z"></path>
                      </g>
                    </svg>
                  </i>{" "}
                  +380686604958
                </a>
              </nav>
            </div>
          </div>

          <div className="mobile-nav hmburger-menu" id="mobile-nav">
            <div className="res-log">
              <a href="/home">
                <img src={logoG} alt="Responsive Logo" />
              </a>
            </div>
            <ul>
              <li className="menu-item-has-children">
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Математика
                </a>
                <ul className="sub-menu">
                  <li>
                    <a href="p1.html">Тема 1</a>
                  </li>
                  <li>
                    <a href="game1.html">Тема 2</a>
                  </li>
                  <li>
                    <a href="#">Тема 3</a>
                  </li>
                  <li>
                    <a href="#">Тема 4</a>
                  </li>
                </ul>
              </li>
              <li className="menu-item-has-children">
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Ігри
                </a>
                <ul className="sub-menu">
                  <li>
                    <a href="game1.html">Порахуй</a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">Послухай</a>
                  </li>
                  <li>
                    <a href="#">Знайди</a>
                  </li>
                  <li>
                    <a href="#">Перевірка знань</a>
                  </li>
                </ul>
              </li>

              <li className="menu-item-has-children">
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Теорія
                </a>

                <ul className="sub-menu">
                  <li>
                    <a href="p1.html">Тема 1</a>
                  </li>
                  <li>
                    <a href="game1.html">Тема 2</a>
                  </li>
                  <li>
                    <a href="#">Тема 3</a>
                  </li>
                  <li>
                    <a href="#">Тема 4</a>
                  </li>
                </ul>
              </li>

              <li className="menu-item-has-children">
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Допомога
                </a>

                <ul className="sub-menu">
                  <li>
                    <a href="about.html">Про додаток</a>
                  </li>
                  <li>
                    <a href="login.html">Служба підтримкм</a>
                  </li>
                  <li>
                    <a href="404-error.html">PDF-підручник</a>
                  </li>
                </ul>
              </li>
            </ul>
            <a href="#" id="res-cross" onClick={(e) => e.preventDefault()}></a>
          </div>
        </header>
      </>
    );
  }
}

export default Header;

import { text_mod_51, } from "../text/Text.js";
export const module51 = {
  title:
    "Літера Ф велика",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_51,
    },
  ]
};
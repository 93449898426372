import { text_mod_40 } from "../text/Text.js";

export const module40 = {
  title: "Велика буква в назвах гір, річок, морів, міст і сіл",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_40,
    },
  ],
};

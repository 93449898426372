import {text_mod_11, } from "../text/Text.js";
export const module11 = {
  title:
    "Зимові історії​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_11, },
    ]
  }

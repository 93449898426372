import { text_mod_91 } from "../text/Text.js";

export const module91 = {
  title: "Текст-міркування",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_91,
    },
  ],
};

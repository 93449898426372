import { text_mod_75, } from "../text/Text.js";
export const module75 = {
  title:
    "Речення. Малі, але важливі слова",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_75,
    },
  ]
};
import { text_mod_36 } from "../text/Text.js";

export const module36 = {
  title: "Повторення знань про різні частини мови",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_36,
    },
  ],
};

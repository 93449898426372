import { text_mod_160 } from "../text/Text.js";
export const module160 = {

  title: "Урок 160. Множення і ділення в межах 1000. Повторення множення чисел на розрядні доданки. Різні способи додавання і віднімання трицифрових чисел. Повторення вивчених випадків додавання і віднімання. Обчислення виразів на всі дії. Розв'язання задач. Повторення вивченого матеріалу.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_160,
    },
  ],
};

import { text_mod_45, } from "../text/Text.js";
export const module45 = {
  title:
    "Літера Є велика",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_45,
    },
  ]
};
import { text_mod_32, } from "../text/Text.js";
export const module32 = {
  title:
    "Літера ж маленька",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_32,
    },
  ]
};
import { text_mod_81 } from "../text/Text.js";

export const module81 = {
  title: "Побудова простих речень",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_81,
    },
  ],
};

import { text_mod_127 } from "../text/Text.js";
export const module127 = {

  title: "Урок 127. Множення і ділення в межах 1000. Закріплення вивчених випадків множення і ділення. Ділення суми на число. Ділення чисел виду 36 : 3, 42 : 3, 64 : 4.  Перевірка ділення множенням. Розв'язання задач.",

  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_127,
    },
  ],
};

import {text_mod_13, } from "../text/Text.js";
export const module13 = {
  title:
    "У світі дивовижних професій ​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_13, },
    ]
  }

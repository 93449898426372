import { text_mod_84, } from "../text/Text.js";
export const module84 = {
  title:
    "Літера Е",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_84,
    },
  ]
};
import { text_mod_5, } from "../text/Text.js";
export const module05 = {
  title:
    "Ми школярі",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_5,
    },
  ]
};
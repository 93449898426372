import { text_mod_33 } from "../text/Text.js";

export const module33 = {
  title: "Суфікс. Роль суфіксів. Творення слів з найуживанішими суфіксами",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_33,
    },
  ],
};

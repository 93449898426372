import { text_mod_28, } from "../text/Text.js";
export const module28 = {
  title:
    "Літера й маленька",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_28,
    },
  ]
};
import { text_mod_24, } from "../text/Text.js";
export const module24 = {
  title:
    "Літера ґ маленька",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_24,
    },
  ]
};
import { text_mod_46, } from "../text/Text.js";
export const module46 = {
  title:
    "Літера ц маленька",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_46,
    },
  ]
};
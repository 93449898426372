import { text_mod_65, } from "../text/Text.js";
export const module65 = {
  title:
    "Буква ї. Звукове значення букви ї",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_65,
    },
  ]
};
import { text_mod_56 } from "../text/Text.js";

export const module56 = {
  title: "Питання числівника",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_56,
    },
  ],
};

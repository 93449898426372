import { text_mod_72 } from "../text/Text.js";

export const module72 = {
  title: "Закріплення та узагальнення вивченого про речення",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_72,
    },
  ],
};

import {text_mod_28, } from "../text/Text.js";
export const module28 = {
  title:
    "Створюю розповідь ​за серією малюнків та опорними словами​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_28, },
    ]
  }

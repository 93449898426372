import { text_mod_66 } from "../text/Text.js";

export const module66 = {
  title: "Дієслова-антоніми",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_66,
    },
  ],
};

import { text_mod_52, } from "../text/Text.js";
export const module52 = {
  title:
    "Звук дж",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_52,
    },
  ]
};
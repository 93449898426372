import { text_mod_86 } from "../text/Text.js";

export const module86 = {
  title: "Урок 86. Нумерація трицифрових чисел. Різні способи віднімання чисел виду 970 – 230. Додавання і віднімання виразів. Перевірка дією додавання. Віднімання чисел виду 400 – 80. Додавання чисел виду 260 + 370. Розв'язання задач.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_86,
    },
  ],
};

import { text_mod_35 } from "../text/Text.js";

export const module35 = {
  title: "Узагальнення вивченого про будову слова",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_35,
    },
  ],
};

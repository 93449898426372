import { text_mod_79 } from "../text/Text.js";

export const module79 = {
  title: "Члени речення. Головні та другорядні. Зв’язок слів у реченні",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_79,
    },
  ],
};


import { text_mod_57, } from "../text/Text.js";
export const module57 = {
  title:
    "Приголосні звуки: тверді та м'які",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_57,
    },
  ]
};
import {text_mod_15, } from "../text/Text.js";
export const module15 = {
  title:
    "Українська мова — державна мова України​.​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_15, },
    ]
  }

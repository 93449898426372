import { routes as mathProshRoutes1 } from "../classes/class1/subjects/math/Proshkuratova/routes/routesConfig";
import { routes as bukvZahRoutes1 } from "../classes/class1/subjects/bukv/Zaharijchuk/routes/routesConfig";

import { routes as ukrmovaZahRoutes2 } from "../classes/class2/subjects/ukrmova/Zaharijchuk/routes/routesConfig";
import { routes as mathProshRoutes2 } from "../classes/class2/subjects/math/Proshkuratova/routes/routesConfig";


import { routes as chutannyaZahRoutes3 } from "../classes/class3/subjects/chutannya/Zaharijchuk/routes/routesConfig";
import { routes as chutannyaZahIvRoutes3 } from "../classes/class3/subjects/chutannya/ZaharijchukIvanchuk/routes/routesConfig";
import { routes as mathProshRoutes3 } from "../classes/class3/subjects/math/Proshkuratova/routes/routesConfig";

import { routes as ukrmovaAvrRoutes8 } from "../classes/class8/subjects/ukrmova/Avramenko/routes/routesConfig";
import { routes as ukrmovaGolRoutes8 } from "../classes/class8/subjects/ukrmova/Golub/routes/routesConfig";
import { routes as ukrlitAvrRoutes8 } from "../classes/class8/subjects/ukrlit/Avramenko/routes/routesConfig";
// import { routes as ukrlitYacRoutes8 } from "../classes/class8/subjects/ukrlit/Yacenko/routes/routesConfig";
import { routes as intYacRoutes8 } from "../classes/class8/subjects/int/Yacenko/routes/routesConfig";
import { routes as zbdPolRoutes8 } from "../classes/class8/subjects/zbd/Polishuk/routes/routesConfig";

export const allRoutes = [
  ...mathProshRoutes1,
  ...ukrmovaZahRoutes2,
  ...mathProshRoutes3,
  ...chutannyaZahRoutes3,
  ...ukrmovaAvrRoutes8,
  ...ukrlitAvrRoutes8,
  ...ukrmovaGolRoutes8,
  // ...ukrlitYacRoutes8,
  ...intYacRoutes8,
  ...zbdPolRoutes8,
  ...bukvZahRoutes1,
  ...mathProshRoutes2,
  ...chutannyaZahIvRoutes3,

];
import { text_mod_68 } from "../text/Text.js";

export const module68 = {
  title: "Пряме й переносне значення дієслова",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_68,
    },
  ],
};

import { text_mod_144 } from "../text/Text.js";
export const module144 = {

  title: "Урок 144. Множення і ділення в межах 1000. Нумерація трицифрових чисел. Додавання і віднімання виразів, пов'язаних із нумерацією. Повторення письмового віднімання трицифрових чисел. Повторення вивчених випадків множення на одноцифрове число. Множення виду 102*3.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_144,
    },
  ],
};

import {text_mod_20, } from "../text/Text.js";
export const module20 = {
  title:
    "Створюю зв’язну розповідь про ситуацію із життя.​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_20, },
    ]
  }

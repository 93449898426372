import { text_mod_53 } from "../text/Text.js";

export const module53 = {
  title: "Уживання прикметників у загадках та описах",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_53,
    },
  ],
};

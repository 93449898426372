import {text_mod_25, } from "../text/Text.js";
export const module25 = {
  title:
    "Мої навчальні досягнення​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_25, },
    ]
  }

import { text_mod_37, } from "../text/Text.js";
export const module37 = {
  title:
    "Весняні свята України",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_37,
    },
  ]
};
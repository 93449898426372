import { text_mod_40, } from "../text/Text.js";
export const module40 = {
  title:
    "Літера я маленька",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_40,
    },
  ]
};
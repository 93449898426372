import { text_mod_93 } from "../text/Text.js";

export const module93 = {
  title: "Засоби зв’язку речень у тексті",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_93,
    },
  ],
};

import { text_mod_17, } from "../text/Text.js";
export const module17 = {
  title:
    "Літера М",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_17,
    },
  ]
};
import { text_mod_80, } from "../text/Text.js";
export const module80 = {
  title:
    "Літера У",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_80,
    },
  ]
};
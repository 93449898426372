import { text_mod_34 } from "../text/Text.js";

export const module34 = {
  title: "Створюю навчальний переказ тексту розповідного змісту за малюнками",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_34,
    },
  ],
};

export const text_mod_1 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQypGA5OiikTZ6fOOjl-rSiAT1EHXL8WkK5J_eHH74AAII"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_2 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQROJcAx5RB4Qor73fu-BJGHAUS6SBKQ3NzFH06E9NEGu2M"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_3 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQR1sYdg6FrNQp6Ps_tD1tAEARCQV5MLD_p5kJ0cOYMdlDQ"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_4 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRX1p5NYs1aRpC8xm6WZgsLAXq4zB5RePY-ulj73Lvc_Ho"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_5 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTBaa_ms03JSqYji9sqRmhBAe71hYoxiMhLzT1VMb8roI0"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_6 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQelv_Zx8i6TIufCpF-TH5VAdxucw6jC2xZOGIcErqSg2k"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_8 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSd1ZPsP1oFTpiBMwGvqDMuAc1O-whX_JGtfm-7VjAU7TI"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_7 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSb-WDeQKJLQLEioxpyYnIJAS4gyjy08KZDx0wBgmo2LW8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;


export const text_mod_9 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQRnx4sVS6QS6TxDj8CvWG2AWgnWudYc6yO3ZnBGKYuaxY"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_10 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRkCsAzvsb6Q7zaac_TvBoyAQva9-nWMGMv0Z7QDIwybKg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_11 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTHnp8HDlHuSIS3jeEJB2hVAQAfEuhBBqMr28HUs6yYM3E"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_12 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQcGFDHBL92QIaU__RGwTShAXF7sY680gjyOP6JyvTXFh0"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_13 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQy7prluOikToQsTHjoZW93ASF_aRCx06vRQmwxP7KJy_g"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_14 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSJ2Jv2u6uNQ7jgdHmYYrP4AcQiH96L08qhIor7ZLGLFbo"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_15 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQS3ZOzKoqCASbdrENIaWSQZAd4xpiLO6oE29xK_MyXpado"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_16 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQT9n5YNER_TRIljErIYQirFAZARHBTwEugXgAGTvPJFycc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_17 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRjPQfc3uxBQJi7Hlc4agcpAT8C4CL3tuu0yz4APpk830A"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_18 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTan2le9_ddS4WVF-xDqGcVAXsW11affTb061oYpS2bMrs"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_19 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQShDMamUJDoTK19D4auEAaYAZAb1bks1p-wEp4MiAbsa98"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_20 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTCKDT5gxaVQYbWRSLNCFpIAReD06OHMP2cEePP9gUwTNc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_21 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSXo9HagDutR6C0dVH6MJMzARNilKea8nFcxwce0zO1sIU"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_22 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTY-g9JG0Q7SJmHbj4fs_50AbwKVnc2aTt0mXyH6q-23wY"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_23 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRyRt3Q2Lp3SLvwQIJgT71IAQevpEZ5hbwV_4h3f0Dw7ks"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_24 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRrHK3e3_x9QIaErCxnO5bgAUlnaBdAVVqhloviJcKC2HI"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_25 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQR-aHr-WfHjQ4XONwbbNEMaAcHQaEuNj2De-ltjS3wO5qo"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_26 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRjJ_q6jlaxTpB2WRrWskVcAaevmqvaxvlzeivvncOYLss"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_27 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTeSyvuLj3SQL1w-mG8CKVlAShbPF0qOJ6F_hfpswBosvs"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_28 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQT9zn-8Z0k2Tre0lYbonwXGAeXqRSuIF3Z_E85fjhBkaGQ"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_29 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSYVYZUzeSpSIMiqcNWD6DXARMmFDzr3rKUsp2kW6pAoaI"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_30 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTd31XL66rYSLT2JxfEq79XAdb0AkZRKEOToEsnfpYXdqY"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_31 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSqrGg9BnbsTrzGEYqJ2osNAXrmCGD-RbjKilg5nTMEScc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_32 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTEnr3YhwgrQIBmkNteHiLiAVXwtn57-goqaN4cYoPlPk4"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_33 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQS8LbzUpHNaTYW5gsvnxk2YAaARgBkiDT09tKJlIblMyKo"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;


export const text_mod_34 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQS7Ygv9l44LQoCVcGgG-_n2AR8T2X-jzoqpenzXy5CsfY4"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_35 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTFs7BS4qGJQZJt2v3BPWtgAWvy6YGwM12xxblUw977b4o"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_36 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQKcn26QGiUTo8yBAo0eH2zAV_GIkSX6yqbPYf_Vw46H6U"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_37 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTm9ibQW31PQIkfWhFjB6lIAfxcImifr5NxsGc-Ei20HOc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_38 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQBBm1QhMqnTbk-Ua4DNCbbARAVSOcOQI_MaQEijoFUuVA"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_39 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTmWbYWU8J4Rbbmy2kYo6QMAXABmsYon0s0OgBmJX_FJ6c"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_40 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTS1JCY4DfARrLz4WjdJzdDATtshB-NxAd11VKOrI1zgBo"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_41 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTTp-Bu5xFoQ7BkQ2OUdH4tAbfsbF5vd3qWVu3LdrigJ9E"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_42 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSrF49MpSs3TZbXgSZTClIdAR7ui8uaLoNjP9wX0drfsI8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_43 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQT2H_c3JJa9SbfTFifggi8PAbTh9ht93I7cQF_YOAy67w4"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_44 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRnrSasOW30SKzdY2k-flqsAa4w36HTll8c4wOf9KRXUwc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_45 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRyk-2qBgbxTJ0VTixu_IecAdyYQLXXBfwT99fUlQrMCNI"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_46 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTOZYU4XQLVS76oj7rO3EX8AU4UHh5bvecmx6KFfrk0HNQ"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_47 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSwBD6-8Hw4QpG9Nwvqj4YqAc_vsFVPy028k5ExNdh1iKU"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_48 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQQcxMpV8MTQo6re88-lVoYAeYZpI02dUxBcVlf7PCG1k8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_49 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTAsr1A-vh8S4uYRopY0E2DAdjVb_XU6PEiJY4hza2TM2s"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_50 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQwbUu04rXdSaJ8R6dDMo3sATr4dbLH7WsYZ1sROeom1EI"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_51 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQy5gA54IYqQrs4dBPS3I98ASFdwVQJ3u9LztQGjCoNOHo"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_52 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSfqqwXDq5pQ72rqW7STcYYAYkYpr0gIH4jdhjAqSt-JmQ"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_53 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSf8cpq1KVgRZCO60FzIce9AQAw3QVERUDutL77Fk9PEoc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_54 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTGtDdc187tQoaxxVlRSU7xAcU-FXWF3t6WktKNTyE6Fs0"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_55 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRM6-WdA6lwS7GYY23gO4dfAXOmVUYXOitkskuj240rN18"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_56 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQe4gGwAb2WQoemxRvGNew_AXQdusSMC-eIVFN7Zyc_fec"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_57 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTfUz7qa6DuTLXieXGk05lwAWHFPbMgNO-vx0CeNALwDDI"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_58 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRSTRWEd5JLQZ6eZntgi1umAY8VCMZ__1HKaXvBLr1RpRw"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_59 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRCU_GHkCv9SKnrEIH6WaIFAYElNeW623Y3iPU-YmApB_c"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_60 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQB3FBO0RqYSKPGcPFNubyUAUxsw1-SHWlA2Za-6DN9IJw"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_61 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQREUBayNa2QSrKlsamFczYDARtCatiLPif7x6xlVO6-C_c"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_62 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRg_-6YTIstQo4OUxKp_oesAZEloNym0KeeO65z-4RuVKw"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_63 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQwDChvKcdxT6mQPRZUKsQ1AUIg_VU19-ifmgRqAwJPhis"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_64 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQJ8H9g0zDER6wMSAQUK7jCAVhbHWVqEtlPzBLGnUn50Fo"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_65 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSa4BHlUEgYQqRzp0A8S5fJAfhBELQEFPwkdfOG0cwKKII"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_66 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRWhmjg-nR0SLpuDepvDAcLAfOwPbuvhICV9OoLJvGO5dI"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_67 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSIegl_wik_Rq-wZ9hUYw6xAUHGaLDkkMBUUkjhqSeyaGI"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_68 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQRlMBUfs0fRKKjyvg-lCT2AQ4tMXHHs0DEHCNMR4XIcWY"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_69 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSZy24Cika6SpnBO9_RGpFxAWotsXVOZy9_D-Sc9BP6hh8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_70 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRiG0EfBJEBRK2acCpKVg7sAdKQCIke6F1ACtp9TqwzNVQ"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_71 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQShzqOHSJSnSIH6jriH90faAQyV9IODceBRwJQjzew8XIs"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_72 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRltSOc7CqAQYGnTjLnz8BRAQTjGLfl9_1Jz8WAzZi3A9A"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_73 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQS6NlunVbRNRYrX5uUfGGydAdRgQwYfrv1gWjmkewfVHH4"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_74 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTauGtJSr8xTr_K4o6e-MICATWA5cKTY36OsXo5L2oPmWw"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_75 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQVdOI22XisTYhJMQzYGYaIAYLwvso8pL7bDW5d7wh1Mzk"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_76 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRIxRvsFVgNSp1zuzhC6DhFAYn-V9ayeRRT0NgrFjf-Kk0"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_77 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSjEt8v_WISQKyIP6Y5h0dYAWlYaydIlBx8IIKyYCetl4U"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_78 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSkr5A61Bs0S7sXJwEw27_xATmSBni-WTt_Y8ilLcv1Ktw"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_79 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTzsSIO_Ok-TYrPFgyrtvZ4ARpw0u1-JTkilWCkV15CViE"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;


export const text_mod_80 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTDMFMNWva7Q5k4w9hbXyeIAS4hrYMo5kvX1vj_3xaPF2k"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_81 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQ8JM-ZQ9HBQrupJhr2P1Q0AS49JNmvul14V1Z4tdrlZ_8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_82 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQToF7MG03knRoBCrEQCPw-lAbODkp63FxAN7jl8vC6cItE"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_83 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRdGxFN3TDcTIMJ1JQ-2smFAdzOO6aHEZKTYMf-VHpGk8o"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_84 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSkFeei0iagS5MEr2kCwZBbAZtdBbzU30JN7v6RJMURURc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_85 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSc_lBnFXEFQo9MxLstwoxfAWTFIqfW0DH7X7kwWhEx6Bg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_86 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRw9FGELPn9Sq23r9cN958YAaTyIR4vnLivqTPgX-vKYSc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_87 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQo-UufSbaRR6WQKpNNe2XzAd8xyi85huiZ32AnB4TQKWs"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_88 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQ7tXXeLiybR6qh1UsUTDENAYLFDZlWEED-eM5SN2C_g-o"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;
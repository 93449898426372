import React, { useState } from "react";
import { ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const sampleData = [
    { id: 1, title: "Українська мова 8 клас Авраменко", link: "/class/8/ukrmova/avr/modules" },
    { id: 2, title: "Українська література 8 клас Голуб", link: "/class/8/ukrmova/gol/modules" },
    { id: 3, title: "Українська література 8 клас Авраменко", link: "/class/8/ukrlit/avr/modules" },
    // { id: 4, title: "Українська література 8 клас Яценко", link: "/class/8/ukrlit/yac/modules" },
    // { id: 5, title: "Українська та зарубіжна література 8 клас Яценко", link: "/class/8/int/yac/modules" },
    { id: 6, title: "Здоров'я, безпека та добробут 8 клас Поліщук", link: "/class/8/zbd/pol/modules" },
    { id: 7, title: "Українська мова та читання 3 клас Захарійчук", link: "/class/3/chutannya/zah/modules" },
    { id: 8, title: "Математика 3 клас Прошкуратова", link: "/class/3/math/prosh/modules" },
    { id: 9, title: "Українська мова 2 клас Захарійчук", link: "/class/2/ukrmova/zah/modules" },
    { id: 10, title: "Математика 2 клас Прошкуратова", link: "/class/2/math/prosh/modules" },
    { id: 11, title: "Математика 1 клас Прошкуратова", link: "/class/1/math/prosh/modules" },
    { id: 12, title: "Буквар 1 клас Захарійчук", link: "/class/1/bukv/zah/modules" },
];

function SearchComponent() {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const navigate = useNavigate();

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);

        if (value.trim() !== "") {
            const filtered = sampleData.filter((item) =>
                item.title.toLowerCase().includes(value)
            );
            setFilteredData(filtered);
        } else {
            setFilteredData([]);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (filteredData.length > 0) {
            navigate(filteredData[0].link);
        } else {
        }
    };

    return (
        <div style={{ position: "relative" }}>
            <form onSubmit={handleSubmit}>
                <input
                    onChange={handleSearchChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setTimeout(() => setIsFocused(false), 100)}
                    type="text"
                    name="Search"
                    placeholder="Пошук..."
                    value={searchTerm}
                    autoComplete="off"
                />
                <button>
                    <i className="fa-solid fa-magnifying-glass"></i>
                </button>
            </form>

            {isFocused && searchTerm.trim() !== "" && (
                <ListGroup
                    style={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        width: "100%",
                        zIndex: 1000,
                        maxHeight: "200px",
                        overflowY: "auto",
                        backgroundColor: "#fff",
                        border: "1px solid #ddd",
                    }}
                >
                    {filteredData.map((item) => (
                        <ListGroup.Item
                            key={item.id}
                            action
                            onClick={() => navigate(item.link)}
                            style={{ cursor: "pointer" }}
                        >
                            {item.title}
                        </ListGroup.Item>
                    ))}
                    {filteredData.length === 0 && (
                        <ListGroup.Item>Нічого не знайдено</ListGroup.Item>
                    )}
                </ListGroup>
            )}
        </div>
    );
}

export default SearchComponent;

import { text_mod_51 } from "../text/Text.js";

export const module51 = {
  title: "Змінювання прикметників за числами в сполученні з іменниками",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_51,
    },
  ],
};

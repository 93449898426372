import {text_mod_9, } from "../text/Text.js";
export const module09 = {
  title:
    "Прийшла до нас бабуся у білому кожусі",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_9, },
    ]
  }

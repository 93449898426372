import { text_mod_149 } from "../text/Text.js";
export const module149 = {

  title: "Урок 149. Множення і ділення в межах 1000. Письмове множення на розрядне число виду 13*60. Визначення часу за годинником. Повторення вивчених випадків ділення. Письмове ділення чисел виду 94 : 4, 628 : 4, 141 : 3. Додавання і віднімання трицифрових чисел.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_149,
    },
  ],
};

import { text_mod_38 } from "../text/Text.js";

export const module38 = {
  title: "Іменник як частина мови. Поняття предметності",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_38,
    },
  ],
};

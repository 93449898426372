import { text_mod_21, } from "../text/Text.js";
export const module21 = {
  title:
    "Літера П",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_21,
    },
  ]
};
import { text_mod_52 } from "../text/Text.js";

export const module52 = {
  title: "Змінювання прикметників за родами в сполученні з іменниками. Родові закінчення прикметників",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_52,
    },
  ],
};

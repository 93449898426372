import { text_mod_82, } from "../text/Text.js";
export const module82 = {
  title:
    "Літера Р",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_82,
    },
  ]
};
import { text_mod_41 } from "../text/Text.js";

export const module41 = {
  title: "Рід іменників: чоловічий, жіночий та середній",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_41,
    },
  ],
};

export const text_mod_1 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLm0AAAAAAACoYOFpXCnbtsSU"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;" <!-- Тут додаємо стиль курсора -->
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_2 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLqkAAAAAAAP3hrG3Ik9CkRvQ"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_3 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLqUAAAAAAAL-Ci7YmzE8P0xA"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_4 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLp0AAAAAAALZJFLN9ba9hp4w"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_5 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLqEAAAAAAAJv8Y9XgUItS3p8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_6 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLpUAAAAAAADiGiUBiQ3zQfcs"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_7 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLpkAAAAAAAHcYjxk2TaBENQo"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_8 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLpEAAAAAAAJkf6er_8c-P2u4"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_9 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLokAAAAAAAEMpvw1pxFcFKBs"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_10 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLo0AAAAAAANKjkcRBC4p9eVc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_11 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLoEAAAAAAAJzn6FTPAF2JCZM"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_12 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLn0AAAAAAANesnq7TzyAzvHY"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_13 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLoUAAAAAAAKm3u5yTUvLtdLk"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_14 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQR41GjK33UaRqdJ__7RPie3AfN4sQkseYPJUNBF-A-3BVk"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_15 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQT6wsqXnejXT4stCfoxg2ubAfYWkIxp1ylKr6aW1cnFkjg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_16 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQX-PS7SifySb84A26t4nBBAcgp50ekhrjfyvQ4fTtnFFU"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_17 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQEDvsXq9NSRZWglbCHCeDmAaSVFCIRBI-jmM-aW-0tG74"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_18 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSfE5dj8Kg1QIpGovuOLhURATlDZZk6Ru7Z8oMtpviIKcc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_19 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTdyiqiMQjCQY3A2H2mnwmKAUtpIituS6bYADacYoJgDVU"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_20 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQR41GjK33UaRqdJ__7RPie3AfN4sQkseYPJUNBF-A-3BVk"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

import {
    module05,
    module06,
    module16,
    module18,
    module23,
    module25,
    module26,
    module30,
    module32,
    module34,
    module36,
    module38,
    module43,
    module46,
    module51,
    module56,
    module60,
    module68,
    module72,
    module81,
    module86,
    module91,
    module98,
    module105,
    module113,
    module117,
    module122,
    module127,
    module133,
    module138,
    module144,
    module149,
    module155,
    module160,

} from "./modules.js";

export const routes = [
    { path: "/class/3/math/prosh/modules/module05", module: module05 },
    { path: "/class/3/math/prosh/modules/module06", module: module06 },
    { path: "/class/3/math/prosh/modules/module16", module: module16 },
    { path: "/class/3/math/prosh/modules/module18", module: module18 },
    { path: "/class/3/math/prosh/modules/module23", module: module23 },
    { path: "/class/3/math/prosh/modules/module25", module: module25 },
    { path: "/class/3/math/prosh/modules/module26", module: module26 },
    { path: "/class/3/math/prosh/modules/module30", module: module30 },
    { path: "/class/3/math/prosh/modules/module32", module: module32 },
    { path: "/class/3/math/prosh/modules/module34", module: module34 },
    { path: "/class/3/math/prosh/modules/module36", module: module36 },
    { path: "/class/3/math/prosh/modules/module38", module: module38 },
    { path: "/class/3/math/prosh/modules/module43", module: module43 },
    { path: "/class/3/math/prosh/modules/module46", module: module46 },
    { path: "/class/3/math/prosh/modules/module51", module: module51 },
    { path: "/class/3/math/prosh/modules/module56", module: module56 },
    { path: "/class/3/math/prosh/modules/module60", module: module60 },
    { path: "/class/3/math/prosh/modules/module68", module: module68 },
    { path: "/class/3/math/prosh/modules/module72", module: module72 },
    { path: "/class/3/math/prosh/modules/module81", module: module81 },
    { path: "/class/3/math/prosh/modules/module86", module: module86 },
    { path: "/class/3/math/prosh/modules/module91", module: module91 },
    { path: "/class/3/math/prosh/modules/module98", module: module98 },
    { path: "/class/3/math/prosh/modules/module105", module: module105 },
    { path: "/class/3/math/prosh/modules/module113", module: module113 },
    { path: "/class/3/math/prosh/modules/module117", module: module117 },
    { path: "/class/3/math/prosh/modules/module122", module: module122 },
    { path: "/class/3/math/prosh/modules/module127", module: module127 },
    { path: "/class/3/math/prosh/modules/module133", module: module133 },
    { path: "/class/3/math/prosh/modules/module138", module: module138 },
    { path: "/class/3/math/prosh/modules/module144", module: module144 },
    { path: "/class/3/math/prosh/modules/module149", module: module149 },
    { path: "/class/3/math/prosh/modules/module155", module: module155 },
    { path: "/class/3/math/prosh/modules/module160", module: module160 },
];
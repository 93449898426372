import { text_mod_71 } from "../text/Text.js";

export const module71 = {
  title: "Створюю художній опис за поданим зразком, використовуючи інформацію з різних джерел",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_71,
    },
  ],
};

import { text_mod_76 } from "../text/Text.js";

export const module76 = {
  title: "Звертання, розділові знаки при них",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_76,
    },
  ],
};

import { text_mod_138 } from "../text/Text.js";
export const module138 = {

  title: "Урок 138. Множення і ділення в межах 1000. Ділення з остачею. Перевірка правильності ділення з остачею. Розв'язання задач. ",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_138,
    },
  ],
};

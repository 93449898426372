import { text_mod_22, } from "../text/Text.js";
export const module22 = {
  title:
    "Літера г маленька ",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_22,
    },
  ]
};
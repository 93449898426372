import { text_mod_73 } from "../text/Text.js";

export const module73 = {
  title: "Речення за метою висловлювання та інтонацією",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_73,
    },
  ],
};

import { text_mod_117 } from "../text/Text.js";
export const module117 = {

  title: "Урок 117. Множення і ділення в межах 1000. Множення чисел виду 2*50, 2*400. Розв'язання задач із «зайвими» даними. Ділення круглих чисел виду 60:3, 600:3. Обчислення чисел виду 400*2, 60:4. Ділення числа на добуток. Ділення круглих чисел виду 600:200.",

  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_117,
    },
  ],
};

import { text_mod_72, } from "../text/Text.js";
export const module72 = {
  title:
    "Слова — назви ознак. Слова, протилежні за значенням",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_72,
    },
  ]
};
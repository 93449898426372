import { text_mod_105 } from "../text/Text.js";
export const module105 = {

  title: "Урок 105. Нумерація трицифрових чисел. Письмове віднімання трицифрових чисел виду 354-138, 623-347. Перевірка додавання трицифрових чисел дією віднімання. Обчислення значень виразів на 2 дії. Розв'язання задач.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_105,
    },
  ],
};

export const modulesData = {
  1: {
    bukv: {
      zah: [
        { path: "module01", title: "Перша частина. Я і моя родина" },
        { path: "module02", title: "Перша частина. У гості до бабусі і дідуся" },
        { path: "module03", title: "Перша частина. Я допомагаю своїй родині" },
        { path: "module04", title: "Перша частина. Мої іграшки" },
        { path: "module05", title: "Перша частина. Ми школярі" },
        { path: "module06", title: "Перша частина. Моя школа" },
        { path: "module07", title: "Перша частина. Я збираюся до школи" },
        { path: "module08", title: "Перша частина. Мої друзі" },
        { path: "module09", title: "Перша частина. Моє дозвілля" },
        { path: "module10", title: "Перша частина. Мій день" },
        { path: "module11", title: "Перша частина. Мій день народження" },
        { path: "module12", title: "Перша частина. Сонячна країна — моя Україна" },
        { path: "module78", title: "Перша частина. Літера А" },
        { path: "module79", title: "Перша частина. Літера О" },
        { path: "module80", title: "Перша частина. Літера У" },
        { path: "module81", title: "Перша частина. Літера И" },
        { path: "module17", title: "Перша частина. Літера М" },
        { path: "module15", title: "Перша частина. Літера І" },
        { path: "module18", title: "Перша частина. Літера Н" },
        { path: "module19", title: "Перша частина. Літера В" },
        { path: "module14", title: "Перша частина. Літера Л" },
        { path: "module20", title: "Перша частина. Літера С" },
        { path: "module16", title: "Перша частина. Літера К" },
        { path: "module21", title: "Перша частина. Літера П" },
        { path: "module82", title: "Перша частина. Літера Р" },
        { path: "module83", title: "Перша частина. Літера Т" },
        { path: "module84", title: "Перша частина. Літера Е" },
        { path: "module85", title: "Перша частина. Літера Д" },
        { path: "module86", title: "Перша частина. Літера З" },
        { path: "module87", title: "Перша частина. Літера Ь" },
        { path: "module88", title: "Перша частина. Літера Б" },
        { path: "module22", title: "Друга частина. Літера г маленька" },
        { path: "module23", title: "Друга частина. Літера Г велика" },
        { path: "module24", title: "Друга частина. Літера ґ маленька" },
        { path: "module25", title: "Друга частина. Літера Ґ велика" },
        { path: "module26", title: "Друга частина. Літера ч маленька" },
        { path: "module27", title: "Друга частина. Літера Ч велика" },
        { path: "module28", title: "Друга частина. Літера й маленька" },
        { path: "module29", title: "Друга частина. Літера Й велика" },
        { path: "module30", title: "Друга частина. Літера х маленька" },
        { path: "module31", title: "Друга частина. Літера Х велика" },
        { path: "module32", title: "Друга частина. Літера ж маленька" },
        { path: "module33", title: "Друга частина. Літера Ж велика" },
        { path: "module34", title: "Друга частина. Літера ш маленька" },
        { path: "module35", title: "Друга частина. Літера Ш велика" },
        { path: "module36", title: "Друга частина. Мої досягнення" },
        { path: "module37", title: "Друга частина. Весняні свята України" },
        { path: "module38", title: "Друга частина. Літера ї маленька" },
        { path: "module39", title: "Друга частина. Літера Ї велика" },
        { path: "module40", title: "Друга частина. Літера я маленька" },
        { path: "module41", title: "Друга частина. Літера Я велика" },
        { path: "module42", title: "Друга частина. Літера ю маленька" },
        { path: "module43", title: "Друга частина. Літера Ю велика" },
        { path: "module44", title: "Друга частина. Літера є маленька" },
        { path: "module45", title: "Друга частина. Літера Є велика" },
        { path: "module46", title: "Друга частина. Літера ц маленька" },
        { path: "module47", title: "Друга частина. Літера Ц велика" },
        { path: "module48", title: "Друга частина. Літера щ маленька" },
        { path: "module49", title: "Друга частина. Літера Щ велика" },
        { path: "module50", title: "Друга частина. Літера ф маленька" },
        { path: "module51", title: "Друга частина. Літера Ф велика" },
        { path: "module52", title: "Друга частина. Звук дж" },
        { path: "module53", title: "Друга частина. Звук дз" },
        { path: "module54", title: "Друга частина. Апостроф" },
        { path: "module55", title: "Друга частина. Мої досягнення" },
        { path: "module56", title: "Третя частина. Абетка. Звуки та букви" },
        { path: "module57", title: "Третя частина. Приголосні звуки: тверді та м'які" },
        { path: "module58", title: "Третя частина. Приголосні звуки: дзвінкі та глухі" },
        { path: "module59", title: "Третя частина. Буква й. Словосполучення йо" },
        { path: "module60", title: "Третя частина. Буква ґ. Вимова звука [ґ]" },
        { path: "module61", title: "Третя частина. Буква я. Звукове значення букви я" },
        { path: "module62", title: "Третя частина. Буква ю. Звукове значення букви ю" },
        { path: "module63", title: "Третя частина. Створення речень за малюнками й опорними словами на задану тему" },
        { path: "module64", title: "Третя частина. Буква є. Звукове значення букви є" },
        { path: "module65", title: "Третя частина. Буква ї. Звукове значення букви ї" },
        { path: "module66", title: "Третя частина. М'який знак (ь). Буквосполучення ьо" },
        { path: "module67", title: "Третя частина. Буквосполучення дж, дз" },
        { path: "module68", title: "Третя частина. Поділ слова на склади. Наголос" },
        { path: "module69", title: "Третя частина. Створюю речення за малюнком з поданих слів на задану тему" },
        { path: "module70", title: "Третя частина. Апостроф" },
        { path: "module71", title: "Третя частина. Слова — назви предметів. Слова, які мають кілька значень" },
        { path: "module72", title: "Третя частина. Слова — назви ознак. Слова, протилежні за значенням" },
        { path: "module73", title: "Третя частина. Слова — назви дій" },
        { path: "module74", title: "Третя частина. Слова, які відповідають на запитання СКІЛЬКИ?" },
        { path: "module75", title: "Третя частина. Речення. Малі, але важливі слова" },
        { path: "module76", title: "Третя частина. Текст" },
        { path: "module77", title: "Третя частина. Мої навчальні досягнення. Я вмію, можу" },
      ],
    },
    math: {
      prosh: [
        { path: "module01", title: "Розділ 1.1. Теми 1-9. Властивості та відношення предметів" },
        { path: "module02", title: "Розділ 1.2. Теми 10-18. Числа 1 - 10" },
        { path: "module03", title: "Розділ 1.3. Теми 19-25. Склад чисел. Додавання чисел" },
        { path: "module04", title: "Розділ 1.4. Теми 26-32. Склад чисел. Віднімання чисел. Вимірювання довжин. Узагальнення і систематизація знань" },
        { path: "module05", title: "Розділ 1.5. Теми 33-36. Додавання і віднімання в межах 10" },
        { path: "module06", title: "Розділ 1.6. Теми 37-47. Додавання і віднімання в межах 10" },
        { path: "module07", title: "Розділ 2.1. Теми 48-57. Задачі" },
        { path: "module08", title: "Розділ 2.2. Теми 58-66. Маса. Одиниця вимірювання маси. Додавання і віднімання іменованих чисел" },
        { path: "module09", title: "Розділ 2.3. Теми 67-76. Розв’язання задач. Обчислення виразів" },
        { path: "module10", title: "Розділ 2.4. Теми 77-85. Розв’язання задач. Обчислення виразів з іменованими числами, вираженими одиницями маси" },
        { path: "module11", title: "Розділ 2.5. Теми 86-94. Порівняння чисел другого десятка. Вимірювання і порівняння довжин відрізків" },
        { path: "module12", title: "Розділ 3.1. Теми 95-102. Лічба десятками в межах 100. Обчислення виразів. Розв’язання задач" },
        { path: "module13", title: "Розділ 3.2. Теми 103-113. Віднімання чисел виду 48 – 8, 48 – 40. Взаємозв’язок дій додавання і віднімання" },
        { path: "module14", title: "Розділ 3.4. Теми 114-123. Рік. Місяць. Тиждень. Доба. Календар" },
        { path: "module15", title: "Розділ 3.5. Теми 124-131. Закріплення віднімання чисел виду 54 – 20. Розв’язання задач" },
        { path: "module16", title: "Розділ 3.6. Теми 132-140. Нумерація чисел у межах 100" },
        { path: "task01", title: "Додаткове завдання тип 1" },
        { path: "task02", title: "Додаткове завдання тип 2" },
        { path: "task03", title: "Додаткове завдання тип 3" },
        { path: "task04", title: "Додаткове завдання тип 4" },
        { path: "task05", title: "Додаткове завдання тип 5" },
      ],
    },
  },
  8: {
    ukrmova: {
      avr: [
        { path: "module01", title: "Словосполучення і речення як одиниці синтаксису. Ознаки словосполучення. Головне й залежне слово в словосполученні. Типи словосполучень за будовою.", pdfPath: "/pdf/ukr_avr_8/module01.pdf", pdfFileName: "Завдання до 01.pdf" },
        { path: "module02", title: "Граматична помилка в словосполученні.", pdfPath: "/pdf/ukr_avr_8/module02.pdf", pdfFileName: "Завдання до 02.pdf" },
        { path: "module03", title: "Речення та його ознаки. Типи речень за метою висловлювання та за емоційним забарвленням. (повторення)", pdfPath: "/pdf/ukr_avr_8/module03.pdf", pdfFileName: "Завдання до 03.pdf" },
        { path: "module2", title: "§ 2. Самостійні і службові частини мови, вигук", pdfPath: "/pdf/ukr_avr_8/module2.pdf", pdfFileName: "Завдання до §2.pdf" },
        { path: "module3", title: "§ 3. Дієслово та його форми", pdfPath: "/pdf/ukr_avr_8/module3.pdf", pdfFileName: "Завдання до §3.pdf" },
        { path: "module4", title: "§ 4. Розряди прислівників за значенням", pdfPath: "/pdf/ukr_avr_8/module4.pdf", pdfFileName: "Завдання до §4.pdf" },
        { path: "module5", title: "§ 5. Сполучники сурядності та підрядності.", pdfPath: "/pdf/ukr_avr_8/module5.pdf", pdfFileName: "Завдання до §5.pdf" },
        { path: "module6", title: "§ 6. Написання прислівників", pdfPath: "/pdf/ukr_avr_8/module6.pdf", pdfFileName: "Завдання до §6.pdf" },
        { path: "module7", title: "§ 7. Написання НЕ з різними частинами мови", pdfPath: "/pdf/ukr_avr_8/module7.pdf", pdfFileName: "Завдання до §7.pdf" },
        { path: "module8", title: "§ 8. Уживання прийменників у, в і початкових у-, в-; сполучників, часток і, й та початкових і-, й-; прийменника з і його варіантів із, зі (зо).", pdfPath: "/pdf/ukr_avr_8/module8.pdf", pdfFileName: "Завдання до §8.pdf" },
        { path: "module9", title: "§ 9. Граматичні помилки.", pdfPath: "/pdf/ukr_avr_8/module9.pdf", pdfFileName: "Завдання до §9.pdf" },
        { path: "module18_19", title: "§ 18–19. Граматична основа двоскладного речення. Підмет", pdfPath: "/pdf/ukr_avr_8/module18_19.pdf", pdfFileName: "Завдання до §18-19.pdf" },
        { path: "module20_21", title: "§ 20–21. Граматична основа двоскладного речення. Присудок", pdfPath: "/pdf/ukr_avr_8/module20_21.pdf", pdfFileName: "Завдання до §20-21.pdf" },
        { path: "module23", title: "§ 23. Узгодження підмета й присудка. Тире між підметом і присудком", pdfPath: "/pdf/ukr_avr_8/module23.pdf", pdfFileName: "Завдання до §23.pdf" },
        { path: "module41", title: "§ 41. Типи односкладних речень", pdfPath: "/pdf/ukr_avr_8/module41.pdf", pdfFileName: "Завдання до §41.pdf" },
        { path: "module42_43", title: "§ 42–43. Означено-особові речення. Неозначено-особові речення", pdfPath: "/pdf/ukr_avr_8/module42_43.pdf", pdfFileName: "Завдання до §42-43.pdf" },
        { path: "module45", title: "§ 45. Узагальнено-особові речення", pdfPath: "/pdf/ukr_avr_8/module45.pdf", pdfFileName: "Завдання до §45.pdf" },
        { path: "module47", title: "§ 47. Безособові речення", pdfPath: "/pdf/ukr_avr_8/module47.pdf", pdfFileName: "Завдання до §47.pdf" },
        { path: "module48", title: "§ 48. Називні речення", pdfPath: "/pdf/ukr_avr_8/module48.pdf", pdfFileName: "Завдання до §48.pdf" },
        { path: "module50", title: "§ 50. Повні і неповні речення. Тире в неповних реченнях", pdfPath: "/pdf/ukr_avr_8/module50.pdf", pdfFileName: "Завдання до §50.pdf" },
        { path: "module52_53", title: "§ 52–53. Однорідні члени речення", pdfPath: "/pdf/ukr_avr_8/module52_53.pdf", pdfFileName: "Завдання до §52-53.pdf" },
        { path: "module54_55", title: "§ 54–55. Розділові знаки між однорідними членами речення", pdfPath: "/pdf/ukr_avr_8/module54_55.pdf", pdfFileName: "Завдання до §54-55.pdf" },
        { path: "module57", title: "§ 57. Однорідні й неоднорідні означення", pdfPath: "/pdf/ukr_avr_8/module57.pdf", pdfFileName: "Завдання до §57.pdf" },
        { path: "module59_60", title: "§ 59–60. Узагальнювальні слова в реченнях з однорідними членами", pdfPath: "/pdf/ukr_avr_8/module59_60.pdf", pdfFileName: "Завдання до §59-60.pdf" },
        { path: "module62_63", title: "§ 62–63. Звертання", pdfPath: "/pdf/ukr_avr_8/module62_63.pdf", pdfFileName: "Завдання до §62-63.pdf" },
        { path: "module65_66", title: "§ 65–66. Вставні слова, словосполучення та речення", pdfPath: "/pdf/ukr_avr_8/module65_66.pdf", pdfFileName: "Завдання до §65-66.pdf" },
        { path: "module69", title: "§ 69. Відокремлені члени речення", pdfPath: "/pdf/ukr_avr_8/module69.pdf", pdfFileName: "Завдання до §69.pdf" },
        { path: "module70_71", title: "§ 70–71. Відокремлені означення", pdfPath: "/pdf/ukr_avr_8/module70_71.pdf", pdfFileName: "Завдання до §70-71.pdf" },
        { path: "module73", title: "§ 73. Відокремлені прикладки", pdfPath: "/pdf/ukr_avr_8/module73.pdf", pdfFileName: "Завдання до §73.pdf" },
        { path: "module74_75", title: "§ 74–75. Відокремлені обставини", pdfPath: "/pdf/ukr_avr_8/module74_75.pdf", pdfFileName: "Завдання до §74-75.pdf" },
        { path: "module77", title: "§ 77. Відокремлені додатки", pdfPath: "/pdf/ukr_avr_8/module77.pdf", pdfFileName: "Завдання до §77.pdf" },
        { path: "module78_79", title: "§ 78–79. Відокремлені уточнювальні члени речення", pdfPath: "/pdf/ukr_avr_8/module78_79.pdf", pdfFileName: "Завдання до §78-79.pdf" },
        { path: "module80", title: "§ 80. Словосполучення і речення. Головні та другорядні члени речення", pdfPath: "/pdf/ukr_avr_8/module80.pdf", pdfFileName: "Завдання до §80.pdf" },
        { path: "module81", title: "§ 81. Односкладне речення. Неповне речення", pdfPath: "/pdf/ukr_avr_8/module81.pdf", pdfFileName: "Завдання до §81.pdf" },
        { path: "module82", title: "§ 82. Розділові знаки в простому ускладненому реченні", pdfPath: "/pdf/ukr_avr_8/module82.pdf", pdfFileName: "Завдання до §82.pdf" }
      ],

      gol: [
        { path: "module01", title: "ОДНОСКЛАДНЕ РЕЧЕННЯ. Тест 1. Односкладні речення. Односкладне називне речення", pdfPath: "/pdf/ukr_gol_8/module01.pdf", pdfFileName: "Тест 1.pdf" },
        { path: "module02", title: "ОДНОСКЛАДНЕ РЕЧЕННЯ. Тест 2. Односкладні речення з головним членом у формі присудка", pdfPath: "/pdf/ukr_gol_8/module02.pdf", pdfFileName: "Тест 2.pdf" },
        { path: "module03", title: "ОДНОСКЛАДНЕ РЕЧЕННЯ. Тест 3. Неозначено-особові речення", pdfPath: "/pdf/ukr_gol_8/module03.pdf", pdfFileName: "Тест 3.pdf" },
        { path: "module04", title: "ОДНОСКЛАДНЕ РЕЧЕННЯ. Тест 4. Узагальнено-особові речення", pdfPath: "/pdf/ukr_gol_8/module04.pdf", pdfFileName: "Тест 4.pdf" },
        { path: "module05", title: "ОДНОСКЛАДНЕ РЕЧЕННЯ. Тест 5. Безособові речення", pdfPath: "/pdf/ukr_gol_8/module05.pdf", pdfFileName: "Тест 5.pdf" },
        { path: "module06", title: "ОДНОСКЛАДНЕ РЕЧЕННЯ. Тест 6. Повні й неповні речення. Тире в неповних реченнях", pdfPath: "/pdf/ukr_gol_8/module06.pdf", pdfFileName: "Тест 6.pdf" },
        { path: "module07", title: "ПРОСТЕ УСКЛАДНЕНЕ РЕЧЕННЯ. Тест 7. Однорідні члени речення", pdfPath: "/pdf/ukr_gol_8/module07.pdf", pdfFileName: "Тест 7.pdf" },
        { path: "module08", title: "ПРОСТЕ УСКЛАДНЕНЕ РЕЧЕННЯ. Тест 8. Узагальнювальні слова в реченнях з однорідними членами та розділові знаки при них", pdfPath: "/pdf/ukr_gol_8/module08.pdf", pdfFileName: "Тест 8.pdf" },
        { path: "module09", title: "ПРОСТЕ УСКЛАДНЕНЕ РЕЧЕННЯ. Тест 9. Поняття про відокремлення. Відокремлення узгоджених означень", pdfPath: "/pdf/ukr_gol_8/module09.pdf", pdfFileName: "Тест 9.pdf" },
        { path: "module10", title: "ІНФОРМАЦІЯ. Тест 10", pdfPath: "/pdf/ukr_gol_8/module10.pdf", pdfFileName: "Тест 10.pdf" },
        { path: "module11", title: "ІНФОРМАЦІЯ. Тест 11", pdfPath: "/pdf/ukr_gol_8/module11.pdf", pdfFileName: "Тест 11.pdf" },
        { path: "module12", title: "ТЕКСТ. Тест 12. Види критичного читання", pdfPath: "/pdf/ukr_gol_8/module12.pdf", pdfFileName: "Тест 12.pdf" },
        { path: "module13", title: "ТЕКСТ. Тест 13. Одиничний і множинний тексти", pdfPath: "/pdf/ukr_gol_8/module13.pdf", pdfFileName: "Тест 13.pdf" },
        { path: "module14", title: "ЖАНРИ МОВЛЕННЯ. Тест 14", pdfPath: "/pdf/ukr_gol_8/module14.pdf", pdfFileName: "Тест 14.pdf" },
        { path: "module15", title: "ЖАНРИ МОВЛЕННЯ. Тест 15. Складники друкованого цифрового тексту", pdfPath: "/pdf/ukr_gol_8/module15.pdf", pdfFileName: "Тест 15.pdf" },
        { path: "module16", title: "СПІЛКУВАННЯ. Тест 16", pdfPath: "/pdf/ukr_gol_8/module16.pdf", pdfFileName: "Тест 16.pdf" },
      ],
    },
    ukrlit: {
      avr: [
        { path: "module01", title: "§ 1. Вступ. Художній твір в історико-культурному контексті доби.", pdfPath: "/pdf/ukrlit_avr_8/module01.pdf", pdfFileName: "Завдання до §1.pdf" },
        { path: "module02", title: "§ 2-3. Культура й віра давніх українців. Історичне оповідання Івана Білика «Дарунки скіфів».", pdfPath: "/pdf/ukrlit_avr_8/module02.pdf", pdfFileName: "Завдання до §2-3.pdf" },
        { path: "module03", title: "§ 4-5. Русь, її культура та письменство. Старина про Іллю Муромця та Солов’я.", pdfPath: "/pdf/ukrlit_avr_8/module03.pdf", pdfFileName: "Завдання до §4-5.pdf" },
        { path: "module04", title: "§ 6-7. Своєрідність давнього українського письменства. «Повчання дітям» Володимира Мономаха.", pdfPath: "/pdf/ukrlit_avr_8/module04.pdf", pdfFileName: "Завдання до §6-7.pdf" },
        { path: "module05", title: "§ 8-9. Образ Русі в сучасній українській літературі.", pdfPath: "/pdf/ukrlit_avr_8/module05.pdf", pdfFileName: "Завдання до §8-9.pdf" },
        { path: "module06", title: "§ 10-11. Біблія – одна зі священних книг.", pdfPath: "/pdf/ukrlit_avr_8/module06.pdf", pdfFileName: "Завдання до §10-11.pdf" },
        { path: "module07", title: "§ 12-14. Біблія та українська література.", pdfPath: "/pdf/ukrlit_avr_8/module07.pdf", pdfFileName: "Завдання до §12-14.pdf" },
        { path: "module08", title: "§ 15. Народні думи.", pdfPath: "/pdf/ukrlit_avr_8/module08.pdf", pdfFileName: "Завдання до §15.pdf" },
        { path: "module09", title: "§ 16-17. Народні історичні пісні.", pdfPath: "/pdf/ukrlit_avr_8/module09.pdf", pdfFileName: "Завдання до §16-17.pdf" },
        { path: "module10", title: "§ 18. Барокова поезія", pdfPath: "/pdf/ukrlit_avr_8/module10.pdf", pdfFileName: "Завдання до §18.pdf" },
        { path: "module11", title: "§ 19-20. Доба козацької України в сучасній літературі", pdfPath: "/pdf/ukrlit_avr_8/module11.pdf", pdfFileName: "Завдання до §19-20.pdf" },
        { path: "module22_23", title: "§ 21-23. Давня кримськотатарська література. Кримські татари й українці в сучасній літературі", pdfPath: "/pdf/ukrlit_avr_8/module22_23.pdf", pdfFileName: "Завдання до §22-23.pdf" },
        { path: "module24_25", title: "§ 24-25. Романтизм і реалізм як основні мистецькі напрями ХІХ ст.", pdfPath: "/pdf/ukrlit_avr_8/module24_25.pdf", pdfFileName: "Завдання до §24-25.pdf" },
        { path: "module26_27", title: "§ 26-27. Жанр балади в літературі романтизму", pdfPath: "/pdf/ukrlit_avr_8/module26_27.pdf", pdfFileName: "Завдання до §26-27.pdf" },
        { path: "module29_31", title: "§ 29-31. Романтична й реалістична проза в українській літературі ХІХ ст. Риси романтичного стилю в повісті Миколи Гоголя «Сорочинський ярмарок»", pdfPath: "/pdf/ukrlit_avr_8/module29_31.pdf", pdfFileName: "Завдання до §29-31.pdf" },
        { path: "module32_34", title: "§ 32-34. Риси реалізму в повісті Івана Нечуя-Левицького «Микола Джеря»", pdfPath: "/pdf/ukrlit_avr_8/module32_34.pdf", pdfFileName: "Завдання до §32-34.pdf" },
        { path: "module35_38", title: "§ 35-38. Комедія Григорія Квітки-Основ’яненка «Сватання на Гончарівці»", pdfPath: "/pdf/ukrlit_avr_8/module35_38.pdf", pdfFileName: "Завдання до §35-38.pdf" },
        { path: "module39_41", title: "§ 39-41. Проблематика п’єси Михайла Старицького «За двома зайцями»", pdfPath: "/pdf/ukrlit_avr_8/module39_41.pdf", pdfFileName: "Завдання до §39-41.pdf" },
      ],
      // yac: [
      //   { path: "module01", title: "Тема 1. Художній твір в історико-культурному контексті доби", pdfPath: "/pdf/ukrlit_yac_8/module01.pdf", pdfFileName: "Завдання до §1.pdf" },
      //   { path: "module02", title: "Тема 2. ПРАДАВНЯ УКРАЇНА В ДЗЕРКАЛІ ЛІТЕРАТУРИ. Культура й віра прадавніх українців", pdfPath: "/pdf/ukrlit_yac_8/module02.pdf", pdfFileName: "Завдання до §2.pdf" },
      //   { path: "module03", title: "Тема 3. Іван Білик «Дарунки скіфів»", pdfPath: "/pdf/ukrlit_yac_8/module03.pdf", pdfFileName: "Завдання до §3.pdf" },
      //   { path: "module04", title: "Тема 4. Русь, її культура та письменство. Усний народний епос. Старини (билини). Старина «Про Іллю Муромця та Соловія»", pdfPath: "/pdf/ukrlit_yac_8/module04.pdf", pdfFileName: "Завдання до §4.pdf" },
      //   { path: "module05", title: "Тема 5. Своєрідність давнього українського письменства. Володимир Мономах «Повчання дітям»", pdfPath: "/pdf/ukrlit_yac_8/module05.pdf", pdfFileName: "Завдання до §5.pdf" },
      //   { path: "module06", title: "Тема 6. Образ Русі в сучасній українській літературі. Раїса Іванченко «Ярославни»", pdfPath: "/pdf/ukrlit_yac_8/module06.pdf", pdfFileName: "Завдання до §6.pdf" },
      //   { path: "module07", title: "Тема 7. Заповідь любові: Біблія та українська література. Біблія – одна зі священних книг людства. «Євангеліє від Матвія»", pdfPath: "/pdf/ukrlit_yac_8/module07.pdf", pdfFileName: "Завдання до §7.pdf" },
      //   { path: "module08", title: "Тема 8. Біблія й українська література. Ліна Костенко «Давидові псалми. Псалом 1»", pdfPath: "/pdf/ukrlit_yac_8/module08.pdf", pdfFileName: "Завдання до §8.pdf" },
      //   { path: "module09", title: "Тема 9. Дзвінка Матіяш «Мене звати Варвара»", pdfPath: "/pdf/ukrlit_yac_8/module09.pdf", pdfFileName: "Завдання до §9.pdf" },
      //   { path: "module10", title: "Тема 10. Література козацької України. Україна в добу Відродження", pdfPath: "/pdf/ukrlit_yac_8/module10.pdf", pdfFileName: "Завдання до §10.pdf" },
      //   { path: "module11", title: "Тема 11. Український народний героїчний ліро-епос. Народні думи. «Дума про Марусю Богуславку»", pdfPath: "/pdf/ukrlit_yac_8/module11.pdf", pdfFileName: "Завдання до §11.pdf" },
      //   { path: "module12", title: "Тема 12. Українські народні історичні пісні. Пісні про боротьбу українського народу за свободу та державність. «Ой як крикнув же козак Сірко»", pdfPath: "/pdf/ukrlit_yac_8/module12.pdf", pdfFileName: "Завдання до §12.pdf" },
      //   { path: "module13", title: "Тема 13. «Чи не той то хміль» — народна пісня про перемогу козацького війська у битві під Жовтими Водами", pdfPath: "/pdf/ukrlit_yac_8/module13.pdf", pdfFileName: "Завдання до §13.pdf" },
      //   { path: "module14", title: "Тема 14. Українське Бароко. Семен Климовський «Їхав козак за Дунай»", pdfPath: "/pdf/ukrlit_yac_8/module14.pdf", pdfFileName: "Завдання до §14.pdf" },
      //   { path: "module15", title: "Тема 15. Доба козацької України в сучасній літературі. Марія Морозенко «Іван Сірко – великий характерник»", pdfPath: "/pdf/ukrlit_yac_8/module15.pdf", pdfFileName: "Завдання до §15.pdf" },
      //   { path: "module16", title: "Тема 16. Українці та кримські татари. Переказ «Фонтан сліз у Бахчисарайському палаці»", pdfPath: "/pdf/ukrlit_yac_8/module16.pdf", pdfFileName: "Завдання до §16.pdf" },
      //   { path: "module17", title: "Тема 17. Ашик Омер", pdfPath: "/pdf/ukrlit_yac_8/module17.pdf", pdfFileName: "Завдання до §17.pdf" },
      //   { path: "module18", title: "Тема 18. Кримські татари та українці в сучасній українській літературі", pdfPath: "/pdf/ukrlit_yac_8/module18.pdf", pdfFileName: "Завдання до §18.pdf" },
      //   { path: "module19", title: "Тема 19. Романтизм в українській літературі", pdfPath: "/pdf/ukrlit_yac_8/module19.pdf", pdfFileName: "Завдання до §19.pdf" },
      //   { path: "module20", title: "Тема 20. Романтизм у творчості Віктора Забіли", pdfPath: "/pdf/ukrlit_yac_8/module20.pdf", pdfFileName: "Завдання до §20.pdf" },
      //   { path: "module21", title: "Тема 21. Жанр балади в літературі романтизму. Тарас Шевченко «Причинна»", pdfPath: "/pdf/ukrlit_yac_8/module21.pdf", pdfFileName: "Завдання до §21.pdf" },
      //   { path: "module22", title: "Тема 22. Сучасні українські балади", pdfPath: "/pdf/ukrlit_yac_8/module22.pdf", pdfFileName: "Завдання до §22.pdf" },
      //   { path: "module23", title: "Тема 23. Традиції романтизму в українській прозі ХІХ століття. Микола Гоголь «Сорочинський ярмарок»", pdfPath: "/pdf/ukrlit_yac_8/module23.pdf", pdfFileName: "Завдання до §23.pdf" },
      //   { path: "module24", title: "Тема 24. Реалізм в українській літературі", pdfPath: "/pdf/ukrlit_yac_8/module24.pdf", pdfFileName: "Завдання до §24.pdf" },
      //   { path: "module25", title: "Тема 25. Поезія реалізму. Павло Грабовський «Я не співець чудовної природи…»", pdfPath: "/pdf/ukrlit_yac_8/module25.pdf", pdfFileName: "Завдання до §25.pdf" },
      //   { path: "module26", title: "Тема 26. Проза реалізму. Іван Нечуй-Левицький «Микола Джеря»", pdfPath: "/pdf/ukrlit_yac_8/module26.pdf", pdfFileName: "Завдання до §26.pdf" },
      //   { path: "module27", title: "Тема 27. Україна ХІХ століття в сучасній літературі. Олександр Гаврош «Врятувати Тараса Шевченка»", pdfPath: "/pdf/ukrlit_yac_8/module27.pdf", pdfFileName: "Завдання до §27.pdf" },
      //   { path: "module28", title: "Тема 28. З української драматургії ХІХ століття", pdfPath: "/pdf/ukrlit_yac_8/module28.pdf", pdfFileName: "Завдання до §28.pdf" },
      //   { path: "module29", title: "Тема 29. Григорій Квітка-Основ’яненко «Сватання на Гончарівці»", pdfPath: "/pdf/ukrlit_yac_8/module29.pdf", pdfFileName: "Завдання до §29.pdf" },
      //   { path: "module30", title: "Тема 30. Михайло Старицький «За двома зайцями»", pdfPath: "/pdf/ukrlit_yac_8/module30.pdf", pdfFileName: "Завдання до §30.pdf" },

      // ],
    },
    int: {
      yac: [
        { path: "module01", title: "Завдання до §1" },
        { path: "module02", title: "Завдання до §2" },
        { path: "module03", title: "Завдання до §3" },
        { path: "module04", title: "Завдання до §4" },
        { path: "module05", title: "Завдання до §5" },
        { path: "module06", title: "Завдання до §6" },
        { path: "module07", title: "Завдання до $7" },
        { path: "module08", title: "Завдання до §8" },
        { path: "module09", title: "Завдання до §9" },
        { path: "module10", title: "Завдання до §10" },
        { path: "module11", title: "Завдання до §11" },
        { path: "module12", title: "Завдання до §12" },
        { path: "module13", title: "Завдання до §13" },
        { path: "module14", title: "Завдання до §14" },
        { path: "module15", title: "Завдання до §15" },
        { path: "module16", title: "Завдання до §16" },
        { path: "module17", title: "Завдання до §17" },
        { path: "module18", title: "Завдання до §18" },
        { path: "module19", title: "Завдання до §19" },
        { path: "module20", title: "Завдання до §20" },
        { path: "module21", title: "Завдання до §21" },
        { path: "module22", title: "Завдання до $22" },
        { path: "module23", title: "Завдання до §23" },
        { path: "module24", title: "Завдання до §24" },
        { path: "module25", title: "Завдання до §25" },
        { path: "module26", title: "Завдання до §26" },
        { path: "module27", title: "Завдання до §27" },
        { path: "module28", title: "Завдання до §28" },
        { path: "module29", title: "Завдання до §29" },
        { path: "module30", title: "Завдання до §30" },
        { path: "module31", title: "Завдання до §31" },
        { path: "module32", title: "Завдання до §32" },
        { path: "module33", title: "Завдання до §33" },
        { path: "module34", title: "Завдання до §34" },
        { path: "module35", title: "Завдання до §35" },
        { path: "module36", title: "Завдання до §36" },
        { path: "module37", title: "Завдання до §37" },
        { path: "module38", title: "Завдання до §38" },
        { path: "module39", title: "Завдання до §39" },
        { path: "module40", title: "Завдання до §40" },
        { path: "module41", title: "Завдання до §41" },
        { path: "module42", title: "Завдання до §42" },
        { path: "module43", title: "Завдання до §43" },
        { path: "module44", title: "Завдання до §44" },
        { path: "module45", title: "Завдання до §45" },
        { path: "module46", title: "Завдання до §46" },
        { path: "module47", title: "Завдання до §47" },
        { path: "module48", title: "Завдання до §48" },
        { path: "module49", title: "Завдання до §49" },
        { path: "module50", title: "Завдання до §50" },
        { path: "module51", title: "Завдання до §51" },
      ],
    },
    zbd: {
      pol: [
        { path: "module01_02", title: "Завдання до уроку 1-2" },
        { path: "module03_04", title: "Завдання до уроку 3-4" },
        { path: "module05", title: "Завдання до уроку 5" },
        { path: "module06", title: "Завдання до уроку 6" },
        { path: "module07_08", title: "Завдання до уроку 7-8" },
        { path: "module09", title: "Завдання до уроку 9" },
        { path: "module15", title: "Завдання до уроку 15" },
        { path: "module17_18", title: "Завдання до уроку 17-18" },
      ],
    },
  },
  3: {
    math: {
      prosh: [
        { path: "module01", title: "Урок 1. Нумерація першої сотні. Додавання і віднімання в межах сотні." },
        { path: "module02", title: "Урок 2. Грошові одиниці. Додавання і віднімання частинами." },
        { path: "module05", title: "Урок 8. Буквені та числові вирази.​ Периметр прямокутника.​." },
        { path: "module06", title: "Урок 9, 12. Переставний закон множення.​ Знаходження невідомих множників. Перевірка ділення за допомогою дії множення. " },
        { path: "module16", title: "Урок 16, 17. Зв’язок множення і ділення. Правило множення на 0. Правило ділення на 0. Назви компонентів під час ділення." },
        { path: "module18", title: "Урок 18, 19. Ділення однакових чисел. Розв'язування задач. ​Одиниці довжини та співвідношення між ними." },
        { path: "module23", title: "Урок 23, 24. Табличне множення числа 2. Парні й непарні числа.​ Ціле. Половина, або одна друга. Задачі на знаходження частини від числа.​" },
        { path: "module25", title: "Урок 25. Табличне множення числа 3. Знаходження частини від числа.​" },
        { path: "module26", title: "Урок 26, 28. Заміна додавання множенням. Табличне множення і ділення числа 4.​ Застосування таблиці множення. Четвертина, або чверть. Час. Квартал.​​" },
        { path: "module30", title: "Урок 30, 31. ​Табличне множення і ділення на 5.​ Знаходження частини від числа.​ Таблиця ділення і множення на 5. Задачі на визначення тривалості подій.​​" },
        { path: "module32", title: "Урок 32, 33. ​Вирази рівності й нерівності. Рівняння.​ Таблиця ділення і множення на 6. Розв'язання рівнянь. ​​" },
        { path: "module34", title: "Урок 34, 35. ​Рівняння. Закріплення таблиці множення числа 6. Задачі. Блок – схеми. ​Дії з іменованими числами. ​​" },
        { path: "module36", title: "Урок 36, 37. ​Ціна. Кількість. Вартість. Складання і розв'язання задач.​ Розв'язання задач на вивчені випадки множення і ділення. ​​" },
        { path: "module38", title: "Урок 38, 39. ​Числовий відрізок. Повторення вивчених випадків множення і ділення.​ Обчислення виразів.​​" },
        { path: "module43", title: "Урок 43, 44. ​Задачі на зведення до одиниці. Обчислення виразів.​ Таблиця множення і ділення на 8. Розв'язання задач і рівнянь. ​​" },
        { path: "module46", title: "Урок 46, 48-49. Таблиця множення і ділення числа 9, 10. Задачі з буквеними даними. Буквені вирази. Знаходження частини від числа.​" },
        { path: "module51", title: "Урок 51-54. Геометричні фігури: площинні, об'ємні.​ Периметр трикутника. Дроби. ​Задачі на розміщення частин від числа та числа за його частиною. ​​" },
        { path: "module56", title: "Урок 56-57. Задачі з буквеними даними. Розв'язання рівнянь.​ Повторення вивчених випадків множення і ділення. Розв'язання задач. ​​" },
        { path: "module60", title: "Урок 60-66. Нумерація трицифрових чисел. Порівняння чисел в межах 1000. Назви розрядів. Співвідношення між одиницями довжини. ​​" },
        { path: "module68", title: "Урок 68-71. Одиниці вимірювання часу. Задачі на визначення тривалості подій, часу. ​Доба. Час за годинником. Округлення до круглих чисел будь – якого розряду. Округлення до сотень. ​​" },
        { path: "module72", title: "Урок 72-76. Співвідношення між одиницями маси. Дії з іменованими числами.  Коло. Радіус і діаметр кола.​ Задачі про спільну роботу та на визначення тривалості і часу подій. Обчислення виразів.​​" },
        { path: "module81", title: "Урок 81-85. Табличне множення і ділення. Обчислення виразів. Обернена задача. Додавання чисел виду 430 + 260. Складання і розв'язання обернених задач. Віднімання чисел виду 960 – 420." },
        { path: "module86", title: "Урок 86-90. Нумерація трицифрових чисел. Різні способи віднімання чисел виду 970 – 230. Додавання і віднімання виразів. Перевірка дією додавання. Віднімання чисел виду 400 – 80. Додавання чисел виду 260 + 370. Розв'язання задач." },
        { path: "module91", title: "Урок 91-95. Нумерація трицифрових чисел. Закріплення вивчених випадків додавання і віднімання у межах 1000. Дії з іменованими числами. Коло. Радіус і діаметр кола. Розв'язування задач." },
        { path: "module98", title: "Урок 98-104. Нумерація трицифрових чисел. Письмове додавання трицифрових чисел виду 124 + 222, 137 + 256. Розв'язання задач із непрямим збільшенням числа. Письмове віднімання чисел виду 563 – 441. Письмове додавання трьох доданків." },
        { path: "module105", title: "Урок 105-110. Нумерація трицифрових чисел. Письмове віднімання трицифрових чисел виду 354-138, 623-347. Перевірка додавання трицифрових чисел дією віднімання. Обчислення значень виразів на 2 дії. Розв'язання задач." },
        { path: "module113", title: "Урок 113-116. Множення чисел на 10 і 100. Порівняння виразів. Ділення круглих чисел на 10 і 100. Дециметр. Робота з геометричним матеріалом. Множення і ділення круглих чисел на 100 і 10." },
        { path: "module117", title: "Урок 117-121. Множення і ділення в межах 1000. Множення чисел виду 2*50, 2*400. Розв'язання задач із «зайвими» даними. Ділення круглих чисел виду 60:3, 600:3. Обчислення чисел виду 400*2, 60:4. Ділення числа на добуток. Ділення круглих чисел виду 600:200." },
        { path: "module122", title: "Урок 122-126. Множення і ділення в межах 1000. Множення суми на число. Робота з діаграмою. Множення чисел виду 15 * 3. Множення числа на суму. Множення чисел виду 4 * 17. Розв'язання задач." },
        { path: "module127", title: "Урок 127-132. Множення і ділення в межах 1000. Закріплення вивчених випадків множення і ділення. Ділення суми на число. Ділення чисел виду 36 : 3, 42 : 3, 64 : 4. Перевірка ділення множенням. Розв'язання задач." },
        { path: "module133", title: "Урок 133-137. Множення і ділення в межах 1000. Знаходження частини від числа. Обчислення частки способом добору. Перевірка ділення множенням. Розв'язання задач і рівнянь. Складання виразів на множення і ділення. Обчислення різними способами." },
        { path: "module138", title: "Урок 138-143. Множення і ділення в межах 1000. Ділення з остачею. Перевірка правильності ділення з остачею. Розв'язання задач." },
        { path: "module144", title: "Урок 144-148. Множення і ділення в межах 1000. Нумерація трицифрових чисел. Додавання і віднімання виразів, пов'язаних із нумерацією. Повторення письмового віднімання трицифрових чисел. Повторення вивчених випадків множення на одноцифрове число. Множення виду 102*3." },
        { path: "module149", title: "Урок 149-154. Множення і ділення в межах 1000. Письмове множення на розрядне число виду 13*60. Визначення часу за годинником. Повторення вивчених випадків ділення. Письмове ділення чисел виду 94 : 4, 628 : 4, 141 : 3. Додавання і віднімання трицифрових чисел." },
        { path: "module155", title: "Урок 155-159. Множення і ділення в межах 1000. Дії з іменованими числами на визначення часу. Додавання і віднімання іменованих чисел. Розв'язання задач про спільну роботу. Обчислення виразів з іменованими числами." },
        { path: "module160", title: "Урок 160-171. Множення і ділення в межах 1000. Повторення множення чисел на розрядні доданки. Різні способи додавання і віднімання трицифрових чисел. Повторення вивчених випадків додавання і віднімання. Обчислення виразів на всі дії. Розв'язання задач. Повторення вивченого матеріалу." },
      ]
    },
    chutannya: {
      zah: [
        { path: "module15", title: "Частина 1. Українська мова — державна мова України" },
        { path: "module16", title: "Частина 1. Українська абетка: звуки та букви" },
        { path: "module17", title: "Частина 1. Голосні звуки. Букви, що їх позначають" },
        { path: "module18", title: "Частина 1. Слова-синоніми" },
        { path: "module19", title: "Частина 1. Ненаголошені голосні" },
        { path: "module20", title: "Частина 1. Вимова та правопис слів із дзвінкими й глухими приголосними звуками" },
        { path: "module21", title: "Частина 1. Вимова та правопис слів із дзвінкими й глухими приголосними звуками" },
        { path: "module22", title: "Частина 1. Створюю фотоколаж «Я і моя родина»" },
        { path: "module23", title: "Частина 1. Подовжені м’які приголосні звуки" },
        { path: "module24", title: "Частина 1. Апостроф" },
        { path: "module25", title: "Частина 1. Мої навчальні досягнення" },
        { path: "module26", title: "Частина 1. Лексичне значення слова" },
        { path: "module27", title: "Частина 1. Однозначні й багатозначні слова" },
        { path: "module28", title: "Частина 1. Створюю розповідь за серією малюнків та опорними словами" },
        { path: "module29", title: "Частина 1. Пряме й переносне значення слова" },
        { path: "module30", title: "Частина 1. Слова-антоніми" },
        { path: "module32", title: "Частина 1. Правопис префіксів роз-, без-. Перенос слів із префіксами. Написання префіксів з-, с-" },
        { path: "module33", title: "Частина 1. Суфікс. Роль суфіксів. Творення слів з найуживанішими суфіксами" },
        { path: "module34", title: "Частина 1. Створюю навчальний переказ тексту розповідного змісту за малюнками" },
        { path: "module35", title: "Частина 1. Узагальнення вивченого про будову слова" },
        { path: "module36", title: "Частина 1. Повторення знань про різні частини мови" },
        { path: "module37", title: "Частина 1. Створюю відгук про прочитаний текст «Подарунок від святого Миколая» (за Дз. Матіяш)" },
        { path: "module38", title: "Частина 1. Іменник як частина мови. Поняття предметності" },
        { path: "module39", title: "Частина 1. Іменники — власні та загальні. Велика буква у власних назвах. Велика буква в кличках тварин" },
        { path: "module40", title: "Частина 1. Велика буква в назвах гір, річок, морів, міст і сіл" },
        { path: "module41", title: "Частина 1. Рід іменників: чоловічий, жіночий та середній" },
        { path: "module42", title: "Частина 1. Створюю навчальний переказ розповідного змісту за поданим планом" },
        { path: "module43", title: "Частина 1. Змінювання іменників за числами" },
        { path: "module44", title: "Частина 1. Прикметник як частина мови" },
        { path: "module45", title: "Частина 1. Роль прикметників у мовленні" },
        { path: "module46", title: "Частина 1. Зв’язок прикметників з іменником" },
        { path: "module47", title: "Частина 1. Уживання прикметників в прямому та переносному значенні" },
        { path: "module48", title: "Частина 1. Прикметники-антоніми" },
        { path: "module49", title: "Частина 1. Створюю навчальний переказ тексту розповідного змісту за малюнками та планом" },
        { path: "module50", title: "Частина 1. Прикметники-синоніми" },
        { path: "module51", title: "Частина 1. Змінювання прикметників за числами в сполученні з іменниками" },
        { path: "module52", title: "Частина 1. Змінювання прикметників за родами в сполученні з іменниками. Родові закінчення прикметників" },
        { path: "module53", title: "Частина 1. Уживання прикметників у загадках та описах" },
        { path: "module54", title: "Частина 1. Уживання прикметників у власних висловленнях" },
        { path: "module55", title: "Частина 1. Числівник як частина мови" },
        { path: "module56", title: "Частина 1. Питання числівника" },
        { path: "module57", title: "Частина 1. Правопис і наголошування числівників" },
        { path: "module58", title: "Частина 1. Вимова та правопис найуживаніших числових виразів" },
        { path: "module59", title: "Частина 1. Створюю розповідь на відому тему" },
        { path: "module60", title: "Частина 1. Вимова та правопис найуживаніших числових виразів" },
        { path: "module61", title: "Частина 1. Дієслово як частина мови" },
        { path: "module62", title: "Частина 1. Зв’язок дієслова з іменником" },
        { path: "module63", title: "Частина 1. Змінювання дієслів за часами" },
        { path: "module64", title: "Частина 1. Написання НЕ з дієсловами" },
        { path: "module65", title: "Частина 1. Створюю письмове висловлення (розповідь) на цікаву тему, використовуючи частини тексту та малюнки" },
        { path: "module66", title: "Частина 1. Дієслова-антоніми" },
        { path: "module67", title: "Частина 1. Дієслова-синоніми" },
        { path: "module68", title: "Частина 1. Пряме й переносне значення дієслова" },
        { path: "module69", title: "Частина 1. Уживання дієслів у власному мовленні" },
        { path: "module70", title: "Частина 1. Узагальнення вивченого про дієслово" },
        { path: "module71", title: "Частина 1. Створюю художній опис за поданим зразком, використовуючи інформацію з різних джерел" },
        { path: "module72", title: "Частина 1. Закріплення та узагальнення вивченого про речення" },
        { path: "module73", title: "Частина 1. Речення за метою висловлювання та інтонацією" },
        { path: "module74", title: "Частина 1. Речення окличні та неокличні" },
        { path: "module75", title: "Частина 1. Спонукальні речення" },
        { path: "module76", title: "Частина 1. Звертання, розділові знаки при них" },
        { path: "module77", title: "Частина 1. Створюю текст-розповідь за малюнками й опорними словами та словосполученнями" },
        { path: "module78", title: "Частина 1. Члени речення. Головні та другорядні. Зв’язок слів у реченні" },
        { path: "module79", title: "Частина 1. Члени речення. Головні та другорядні. Зв’язок слів у реченні" },
        { path: "module80", title: "Частина 1. Словосполучення в групі підмета й присудка" },
        { path: "module81", title: "Частина 1. Побудова простих речень" },
        { path: "module82", title: "Частина 1. Створюю привітання до Дня матері" },
        { path: "module83", title: "Частина 1. Мої навчальні досягнення" },
        { path: "module84", title: "Частина 1. Текст та його ознаки" },
        { path: "module85", title: "Частина 1. Будова тексту" },
        { path: "module86", title: "Частина 1. Художній, науково-популярний та діловий тексти" },
        { path: "module87", title: "Частина 1. План тексту" },
        { path: "module88", title: "Частина 1. Текст-розповідь" },
        { path: "module89", title: "Частина 1. Створюю художній опис за поданим зразком" },
        { path: "module90", title: "Частина 1. Текст-опис" },
        { path: "module91", title: "Частина 1. Текст-міркування" },
        { path: "module92", title: "Частина 1. Текст-есе" },
        { path: "module93", title: "Частина 1. Засоби зв’язку речень у тексті" },
        { path: "module94", title: "Частина 1. Створюю зв’язну розповідь про ситуацію із життя «Мрії про літні канікули»" },
      ],

      zahiv: [
        { path: "module01", title: "Частина 2. Літо, прощавай!" },
        { path: "module02", title: "Частина 2. Родина, родина – це вся Україна" },
        { path: "module03", title: "Частина 2. РОЗГУБИЛА ОСІНЬ ЗОЛОТЕ НАМИСТО…" },
        { path: "module04", title: "Частина 2. Дзвонить осінь школярам…" },
        { path: "module05", title: "Частина 2. Трагічні події в Україні" },
        { path: "module06", title: "Частина 2. Дружба - найцінніший скарб" },
        { path: "module07", title: "Частина 2. Буду я навчатися мови золотої..." },
        { path: "module08", title: "Частина 2. Про одне й те саме в різний спосіб" },
        { path: "module09", title: "Частина 2. Прийшла до нас бабуся у білому кожусі" },
        { path: "module10", title: "Частина 2. Зимові свята" },
        { path: "module11", title: "Частина 2. Зимові історії" },
        { path: "module12", title: "Частина 2. Історія успіху" },
        { path: "module13", title: "Частина 2. У світі дивовижних професій" },
        { path: "module14", title: "Частина 2. Заквітчана, зеленокоса​ до нас прийшла весна​." },
        { path: "module15", title: "Частина 2. ЗАКВІТЧАНА, ЗЕЛЕНОКОСА​ ДО НАС ПРИЙШЛА ВЕСНА" },
        { path: "module16", title: "Частина 2. Березень —​ ранок весни" },
        { path: "module17", title: "Частина 2. Зустрічаймо ​квітень!" },
        { path: "module18", title: "Частина 2. Квітень іде — Великдень веде" },
        { path: "module19", title: "Частина 2. Знайомся!" },
        { path: "module20", title: "Частина 2. Створюю зв’язну розповідь про ситуацію із життя." },
      ]
    },
  },
  2: {
    ukrmova: {
      zah: [
        { path: "module01", title: "Завдання до §1. Ми знову разом​" },
        { path: "module02", title: "Завдання до §2. Українська абетка" },
        { path: "module03", title: "Завдання до §3. Голосні звуки. ​Букви, що їх позначають​" },
        { path: "module04", title: "Завдання до §4. Звукові значення ​букви Я​" },
        { path: "module05", title: "Завдання до §5. Звукові значення ​букви Ю​" },
        { path: "module06", title: "Завдання до §6. Звукові значення ​букви Є" },
        { path: "module07", title: "Завдання до §7. Звукові значення ​букви Ї" },
        { path: "module08", title: "Завдання до §8. Зміна значень слова ​зі зміною звука (букви)" },
        { path: "module09", title: "Завдання до §9. Створюю поради, як користуватися​ книжкою" },
        { path: "module10", title: "Завдання до §10. Наголос.​ Зміна значення слова зі зміною наголосу​" },
        { path: "module11", title: "Завдання до §11. Приголосні звуки. Букви, що їх позначають​" },
        { path: "module12", title: "Завдання до §12. Приголосні звуки [дж ], [дз ], [дз′]. Позначення їх буквами​" },
        { path: "module13", title: "Завдання до §13. Звукове значення букви Щ​" },
        { path: "module14", title: "Завдання до §14. Дзвінкі й глухі приголосні звуки. Дзвінкі приголосні звук в кінці слова та складу перед глухими​" },
        { path: "module15", title: "Завдання до §15. Створюю кілька речень на добре відому тему" },
        { path: "module16", title: "Завдання до §16. Тверді та м’які приголосні звуки. Позначення м’якості приголосних м’яким знаком (ь)" },
        { path: "module17", title: "Завдання до §17. Позначення м’якості приголосних буквами І, Я, Ю, Є" },
        { path: "module18", title: "Завдання до §18. Подовжені м’які приголосні звуки" },
        { path: "module19", title: "Завдання до §19. Апостроф" },
        { path: "module20", title: "Завдання до §20. Мої навчальні досягнення" },
        { path: "module21", title: "Завдання до §21. Склад. Перенос слів із рядка в рядок по складах. Перенос слів, у яких склад позначений однією буквою" },
        { path: "module22", title: "Завдання до §22. Перенос слів із буквосполученнями ьо, йо та дж, дз" },
        { path: "module23", title: "Завдання до §23. Створюю зв’язне висловлення про осінь" },
        { path: "module24", title: "Завдання до §24. Перенос слів з апострофом.Перенос слів із подовженими приголосними звукам" },
        { path: "module25", title: "Завдання до §25. Мої навчальні досягненн. Для кмітливих і допитливих" },
        { path: "module26", title: "Завдання до §26. Слово. Лексичне значення слова" },
        { path: "module27", title: "Завдання до §27. Слова, протилежні за значенням" },
        { path: "module28", title: "Завдання до §28. Створюю розповідь про домашнього улюбленця або іншу тварину" },
        { path: "module29", title: "Завдання до §29. Слова, близькі за значенням" },
        { path: "module30", title: "Завдання до §30. Пряме й переносне значення слів" },
        { path: "module31", title: "Завдання до §31. Однозначні й багатозначні слова" },
        { path: "module32", title: "Завдання до §32. Слова, що називають предмети, ознаки, дії, числа. ​Розподіл слів на групи за значенням і питаннями" },
        { path: "module33", title: "Завдання до §33. Іменник.​ Слова, які відповідають на питання хто? і що?" },
        { path: "module34", title: "Завдання до §34. Зміна іменників ​за числами ​(один – багато)" },
        { path: "module35", title: "Завдання до §35. Іменники, ​протилежні за значенням. ​Іменники, ​близькі за значенням​" },
        { path: "module36", title: "Завдання до §36. Велика буква ​в іменах, по батькові​ та прізвищах​​" },
        { path: "module37", title: "Завдання до §37. Велика буква в кличках тварин​" },
        { path: "module38", title: "Завдання до §38. Велика буква в назвах  вулиць, міст, сіл, річок, гір​​" },
        { path: "module39", title: "Завдання до §39. Повторення вивченого про іменник​​" },
        { path: "module40", title: "Завдання до §40. Мої навчальні досягнення​​" },
        { path: "module41", title: "Завдання до §41. Слова, які називають ознаки предметів​​​" },
        { path: "module42", title: "Завдання до §42. Прикметники, протилежні за значенням​​​" },
        { path: "module43", title: "Завдання до §43. Прикметники, ​близькі за значенням" },
        { path: "module44", title: "Завдання до §44. Сполучення іменників із прикметниками" },
        { path: "module45", title: "Завдання до §45. Мої навчальні досягнення" },
        { path: "module46", title: "Завдання до §46. Дієслово.Слова – назви дій" },
        { path: "module47", title: "Завдання до §47. Добір питань до дієслів" },
        { path: "module48", title: "Завдання до §48. Дієслова, протилежні за значенням. Дієслова, близькі за значенням" },
        { path: "module49", title: "Завдання до §49. Добір дієслів для висловлення власних думок" },
        { path: "module50", title: "Завдання до §50. Створюю та записую речення, використовуючи малюнки й початок казки І.Савки «Обшивайко»" },
        { path: "module51", title: "Завдання до §51. Мої навчальні досягнення" },
        { path: "module52", title: "Завдання до §52. Слова, які називають числа. Сполучення числівників з іменниками" },
        { path: "module53", title: "Завдання до §53. Службові слова в реченні. Правопис службових слів" },
        { path: "module54", title: "Завдання до §54. Речення. Ознаки речення" },
        { path: "module55", title: "Завдання до §55. Розповідні речення. Інтонація розповідних речень" },
        { path: "module56", title: "Завдання до §56. Питальні речення. Інтонація питальних речень" },
        { path: "module57", title: "Завдання до §57. Спонукальні речення. Інтонація" },
        { path: "module58", title: "Завдання до §58. Поширення речень за питаннями та поданими словами" },
        { path: "module59", title: "Завдання до §59. Деформовані речення. Створення речень за малюнками" },
        { path: "module60", title: "Завдання до §60. Текст. Ознаки тексту" },
        { path: "module61", title: "Завдання до §61. Будова тексту" },
        { path: "module62", title: "Завдання до §62. Створюю вітальну листівку для своєї мами" },
        { path: "module63", title: "Завдання до §63. Текст-розповідь" },
        { path: "module64", title: "Завдання до §64. Текст-опис" },
        { path: "module65", title: "Завдання до §65. Виражальні засоби мови. Сворюю текст за ілюстрацією" },
        { path: "module66", title: "Завдання до §66. Створюю текст за серією малюнків. Створюю текст про події з власного життя" },
        { path: "module67", title: "Завдання до §67. Мої начальні досягнення" },
      ],
    },
    math: {
      prosh: [
        { path: "module01", title: "Уроки 1-4. Лічба в межах 10. Складання та обчислення виразів. Додавання і віднімання круглих чисел​" },
        { path: "module02", title: "Уроки 5-7. Задачі на знаходження невідомого доданка. Компоненти під час виконання віднімання. Знаходження невідомого від’ємника. Одиниці вимірювання величин​" },
        { path: "module03", title: "Уроки 10-11. ​Додавання чисел 2–9 до 9 з переходом через 10.​ Додавання чисел 3–9 до 8 з переходом через 10​" },
        { path: "module04", title: "Уроки 12-16. ​Додавання чисел 4–9 до 7 з переходом через 10.​ Додавання чисел 5–9 до 6 з переходом через 10.​ Вимірювання температури​" },
        { path: "module05", title: "Уроки 17-21. Доба. Тиждень. Місяць. Рік. Календар.​ Додавання і віднімання одноцифрових чисел із переходом через 10. Календар осінніх місяців.​ Визначення часу за годинником​​​" },
        { path: "module06", title: "Уроки 24-27. Способи віднімання від 11 одноцифрових чисел.​ Способи віднімання від 12 одноцифрових чисел. Способи віднімання від 13 одноцифрових чисел​" },
        { path: "module07", title: "Уроки 28-30. Способи віднімання від 14 одноцифрових чисел.​ Периметр багатокутника.​ Способи віднімання від 15 одноцифрових чисел​​​​​" },
        { path: "module08", title: "Уроки 31-34. Способи віднімання від 16 одноцифрових чисел.​ Способи віднімання від 17 одноцифрових чисел.​ Способи віднімання від 18 одноцифрових чисел.​ Додавання і віднімання одноцифрових чисел​" },
        { path: "module09", title: "Уроки 35-39. Лічба десятками. Попереднє і наступне числа. Додавання і віднімання іменованих чисел.​ Вирази з дужками. Читання виразів із дужками​" },
        { path: "module10", title: "Уроки 40-43. Додавання і віднімання чисел виду 32 + 4, 28 – 5.​ Віднімання двоцифрових чисел виду 65 – 20​" },
        { path: "module11", title: "Уроки 44-48. Додавання чисел виду 25 + 43. Віднімання чисел виду 34 – 21. Додавання чисел​ виду 26 + 4. Віднімання чисел виду 40 – 3​" },
        { path: "module12", title: "Уроки 49-52. Додавання і віднімання чисел виду 36 + 4, 30 – 3.​ Розв’язання задач на дві дії. Додавання чисел виду 38 + 4, 46 + 5. Додавання суми до числа​" },
        { path: "module13", title: "Уроки 55-60. Міліметр. Вимірювання довжин відрізків.​ Віднімання чисел виду 34 – 6. ​Додавання чисел виду 38 + 25, 43 + 27.​ Додавання двоцифрових чисел​" },
        { path: "module14", title: "Уроки 61-65. Прямий кут. Розпізнавання геометричних фігур.​ Периметр. Букви латинського алфавіту.​ Задачі на знаходження третього доданка.​ Віднімання чисел виду 83 – 46, 70 – 46​​" },
        { path: "module15", title: "Уроки 66-70. Центнер. Дії з іменованими числами.​ Закріплення вивченого матеріалу. Календар зимових місяців. Розв’язування задач. ​Додавання і віднімання чисел​" },
        { path: "module16", title: "Уроки 71-76. Дія множення. Знак множення.​ Перевірка множення додаванням. Складання таблиці множення числа 2. Переставна властивість множення. Розв’язування задач​" },
        { path: "module17", title: "Уроки 78-83. Складання діаграми. Множення із числами 1 і 0. Буквене позначення чисел. Складання таблиці множення числа 3. Периметр прямокутника, п’ятикутника. Задачі на збільшення числа на кілька одиниць​" },
        { path: "module18", title: "Уроки 85-90. Порівняння задач. Обчислення виразів.​ Дія ділення. Знак ділення. Назви компонентів і результатів дії ділення. Зв’язок дій множення і ділення. Складання таблиці ділення на 2. Розв’язання задач на ділення на рівні частини​" },
        { path: "module19", title: "Уроки 91-96. Складання таблиці ділення на 3. Закріплення вивчених випадків множення і ділення.​ Задачі на 2 дії. Ділення чисел на 1. Розв’язання і порівняння задач​​​​" },
        { path: "module20", title: "Уроки 103-107. Складання за схемою добутків із першим множником 5 і частки із дільником 5. Визначення часу за годинником. Хвилина.​ Складання добутків із множником 5​​​" },
        { path: "module21", title: "Уроки 108-113. Буквені вирази. Складання добутків та обчислення їхніх значень. Складання за схемою добутків із першим множником 6. ​Розв’язування задач​​​​​​" },
        { path: "module22", title: "Уроки 114-118. Задачі на знаходження суми двох добутків. Складання за схемою добутків із першим множником 7. Способи обчислення периметра прямокутника, квадрата. Дії з іменованими числами. Знаходження невідомого множника.​ Розв’язання задач​​​" },
        { path: "module23", title: "Уроки 121-126. Складання за схемою добутків із першим множником 8 і 9. Обчислення периметра. Розв’язання задач на вивчені випадки множення і ділення. Знаходження невідомого дільника.​ Відновлення рівностей​​​​​​​​" },
        { path: "module24", title: "Урок 127-133. Складання за схемою добутків із множником 9 і частки з дільником 9. Знаходження невідомого діленого. Множення і ділення на 10. Обчислення виразів на вивчені випадки множення і ділення. Розв’язання задач​" },
      ],
    }
  },
};
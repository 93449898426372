import { text_mod_60 } from "../text/Text.js";

export const module60 = {
  title: "Вимова та правопис найуживаніших числових виразів",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_60,
    },
  ],
};

import { text_mod_83, } from "../text/Text.js";
export const module83 = {
  title:
    "Літера Т",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_83,
    },
  ]
};
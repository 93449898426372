import {
    module01,
    module02,
    module03,
    module04,
    module05,
    module06,
    module07,
    module08,
    module09,
    module10,
    module11,
    module12,
    module13,
    module14,
    module15,
    module16,
    module17,
    module18,
    module19,
    module20,
    module21,
    module22,
    module23,
    module24,
    module25,
    module26,
    module27,
    module28,
    module29,
    module30,
    module31,
    module32,
    module33,
    module34,
    module35,
    module36,
    module37,
    module38,
    module39,
    module40,
    module41,
    module42,
    module43,
    module44,
    module45,
    module46,
    module47,
    module48,
    module49,
    module50,
    module51,
    module52,
    module53,
    module54,
    module55,
    module56,
    module57,
    module58,
    module59,
    module60,
    module61,
    module62,
    module63,
    module64,
    module65,
    module66,
    module67,
    module68,
    module69,
    module70,
    module71,
    module72,
    module73,
    module74,
    module75,
    module76,
    module77,
    module78,
    module79,
    module80,
    module81,
    module82,
    module83,
    module84,
    module85,
    module86,
    module87,
    module88,

} from "./modules.js";

export const routes = [
    { path: "/class/1/bukv/zah/modules/module01", module: module01 },
    { path: "/class/1/bukv/zah/modules/module02", module: module02 },
    { path: "/class/1/bukv/zah/modules/module03", module: module03 },
    { path: "/class/1/bukv/zah/modules/module04", module: module04 },
    { path: "/class/1/bukv/zah/modules/module05", module: module05 },
    { path: "/class/1/bukv/zah/modules/module06", module: module06 },
    { path: "/class/1/bukv/zah/modules/module07", module: module07 },
    { path: "/class/1/bukv/zah/modules/module08", module: module08 },
    { path: "/class/1/bukv/zah/modules/module09", module: module09 },
    { path: "/class/1/bukv/zah/modules/module10", module: module10 },
    { path: "/class/1/bukv/zah/modules/module11", module: module11 },
    { path: "/class/1/bukv/zah/modules/module12", module: module12 },
    { path: "/class/1/bukv/zah/modules/module13", module: module13 },
    { path: "/class/1/bukv/zah/modules/module14", module: module14 },
    { path: "/class/1/bukv/zah/modules/module15", module: module15 },
    { path: "/class/1/bukv/zah/modules/module16", module: module16 },
    { path: "/class/1/bukv/zah/modules/module17", module: module17 },
    { path: "/class/1/bukv/zah/modules/module18", module: module18 },
    { path: "/class/1/bukv/zah/modules/module19", module: module19 },
    { path: "/class/1/bukv/zah/modules/module20", module: module20 },
    { path: "/class/1/bukv/zah/modules/module21", module: module21 },
    { path: "/class/1/bukv/zah/modules/module22", module: module22 },
    { path: "/class/1/bukv/zah/modules/module23", module: module23 },
    { path: "/class/1/bukv/zah/modules/module24", module: module24 },
    { path: "/class/1/bukv/zah/modules/module25", module: module25 },
    { path: "/class/1/bukv/zah/modules/module26", module: module26 },
    { path: "/class/1/bukv/zah/modules/module27", module: module27 },
    { path: "/class/1/bukv/zah/modules/module28", module: module28 },
    { path: "/class/1/bukv/zah/modules/module29", module: module29 },
    { path: "/class/1/bukv/zah/modules/module30", module: module30 },
    { path: "/class/1/bukv/zah/modules/module31", module: module31 },
    { path: "/class/1/bukv/zah/modules/module32", module: module32 },
    { path: "/class/1/bukv/zah/modules/module33", module: module33 },
    { path: "/class/1/bukv/zah/modules/module34", module: module34 },
    { path: "/class/1/bukv/zah/modules/module35", module: module35 },
    { path: "/class/1/bukv/zah/modules/module36", module: module36 },
    { path: "/class/1/bukv/zah/modules/module37", module: module37 },
    { path: "/class/1/bukv/zah/modules/module38", module: module38 },
    { path: "/class/1/bukv/zah/modules/module39", module: module39 },
    { path: "/class/1/bukv/zah/modules/module40", module: module40 },
    { path: "/class/1/bukv/zah/modules/module41", module: module41 },
    { path: "/class/1/bukv/zah/modules/module42", module: module42 },
    { path: "/class/1/bukv/zah/modules/module43", module: module43 },
    { path: "/class/1/bukv/zah/modules/module44", module: module44 },
    { path: "/class/1/bukv/zah/modules/module45", module: module45 },
    { path: "/class/1/bukv/zah/modules/module46", module: module46 },
    { path: "/class/1/bukv/zah/modules/module47", module: module47 },
    { path: "/class/1/bukv/zah/modules/module48", module: module48 },
    { path: "/class/1/bukv/zah/modules/module49", module: module49 },
    { path: "/class/1/bukv/zah/modules/module50", module: module50 },
    { path: "/class/1/bukv/zah/modules/module51", module: module51 },
    { path: "/class/1/bukv/zah/modules/module52", module: module52 },
    { path: "/class/1/bukv/zah/modules/module53", module: module53 },
    { path: "/class/1/bukv/zah/modules/module54", module: module54 },
    { path: "/class/1/bukv/zah/modules/module55", module: module55 },
    { path: "/class/1/bukv/zah/modules/module56", module: module56 },
    { path: "/class/1/bukv/zah/modules/module57", module: module57 },
    { path: "/class/1/bukv/zah/modules/module58", module: module58 },
    { path: "/class/1/bukv/zah/modules/module59", module: module59 },
    { path: "/class/1/bukv/zah/modules/module60", module: module60 },
    { path: "/class/1/bukv/zah/modules/module61", module: module61 },
    { path: "/class/1/bukv/zah/modules/module62", module: module62 },
    { path: "/class/1/bukv/zah/modules/module63", module: module63 },
    { path: "/class/1/bukv/zah/modules/module64", module: module64 },
    { path: "/class/1/bukv/zah/modules/module65", module: module65 },
    { path: "/class/1/bukv/zah/modules/module66", module: module66 },
    { path: "/class/1/bukv/zah/modules/module67", module: module67 },
    { path: "/class/1/bukv/zah/modules/module68", module: module68 },
    { path: "/class/1/bukv/zah/modules/module69", module: module69 },
    { path: "/class/1/bukv/zah/modules/module70", module: module70 },
    { path: "/class/1/bukv/zah/modules/module71", module: module71 },
    { path: "/class/1/bukv/zah/modules/module72", module: module72 },
    { path: "/class/1/bukv/zah/modules/module73", module: module73 },
    { path: "/class/1/bukv/zah/modules/module74", module: module74 },
    { path: "/class/1/bukv/zah/modules/module75", module: module75 },
    { path: "/class/1/bukv/zah/modules/module76", module: module76 },
    { path: "/class/1/bukv/zah/modules/module77", module: module77 },
    { path: "/class/1/bukv/zah/modules/module78", module: module78 },
    { path: "/class/1/bukv/zah/modules/module79", module: module79 },
    { path: "/class/1/bukv/zah/modules/module80", module: module80 },
    { path: "/class/1/bukv/zah/modules/module81", module: module81 },
    { path: "/class/1/bukv/zah/modules/module82", module: module82 },
    { path: "/class/1/bukv/zah/modules/module83", module: module83 },
    { path: "/class/1/bukv/zah/modules/module84", module: module84 },
    { path: "/class/1/bukv/zah/modules/module85", module: module85 },
    { path: "/class/1/bukv/zah/modules/module86", module: module86 },
    { path: "/class/1/bukv/zah/modules/module87", module: module87 },
    { path: "/class/1/bukv/zah/modules/module88", module: module88 },
];

import { text_mod_113 } from "../text/Text.js";
export const module113 = {

  title: "Урок 113. Множення чисел на 10 і 100. Порівняння виразів. Ділення круглих чисел на 10 і 100. Дециметр. Робота з геометричним матеріалом. Множення і ділення круглих чисел на 100 і 10.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_113,
    },
  ],
};

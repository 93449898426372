import { text_mod_18, } from "../text/Text.js";
export const module18 = {
  title:
    "Літера Н",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_18,
    },
  ]
};
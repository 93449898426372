import { text_mod_69, } from "../text/Text.js";
export const module69 = {
  title:
    "Створюю речення за малюнком з поданих слів на задану тему",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_69,
    },
  ]
};
import { text_mod_89 } from "../text/Text.js";

export const module89 = {
  title: "Створюю художній опис за поданим зразком",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_89,
    },
  ],
};

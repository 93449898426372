import { text_mod_39 } from "../text/Text.js";

export const module39 = {
  title: "Іменники — власні та загальні. Велика буква у власних назвах. Велика буква в кличках тварин",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_39,
    },
  ],
};

import { text_mod_63 } from "../text/Text.js";

export const module63 = {
  title: "Змінювання дієслів за часами",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_63,
    },
  ],
};

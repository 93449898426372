import { text_mod_59 } from "../text/Text.js";

export const module59 = {
  title: "Створюю розповідь на відому тему",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_59,
    },
  ],
};

import { text_mod_77 } from "../text/Text.js";

export const module77 = {
  title: "Створюю текст-розповідь за малюнками й опорними словами та словосполученнями",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_77,
    },
  ],
};

import { text_mod_88, } from "../text/Text.js";
export const module88 = {
  title:
    "Літера Б",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_88,
    },
  ]
};
import { text_mod_49 } from "../text/Text.js";

export const module49 = {
  title: "Створюю навчальний переказ тексту розповідного змісту за малюнками та планом",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_49,
    },
  ],
};

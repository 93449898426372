export const text_mod_1 = `
    <h4>8 клас</h4>
    <ol>
        <li><b>Здоров’я, безпека та добробут</b><br/>
        Підручник інтегрованого курсу для 8 класу закладів загальної середньої освіти<br/>
        <i>Автори: Наталія Поліщук, Павло Поліщук</i>
        </li></br>

        <li><b>Українська мова</b><br/>
        Підручник для 8 класу закладів загальної середньої освіти<br/>
        <i>Автори: Н.Б. Голуб, О.М. Горошкіна</i>
        </br>
        <a href="/pdf/books/UAmova_8kl_Goroschkina_1.pdf" download>Завантажити підручник</a>
        </li></br>

        <li><b>Українська література</b><br/>
        Підручник для 8 класу закладів загальної середньої освіти<br/>
        <i>Автор: О.М. Авраменко</i>
         </br>
        <a href="/pdf/books/Ukr_lit_8_kl___Avram.pdf" download>Завантажити підручник</a>
        </li></br>

        <li><b>Українська мова</b><br/>
        Підручник для 8 класу закладів загальної середньої освіти<br/>
        <i>Автор: О.М. Авраменко</i>
          </br>
        <a href="/pdf/books/Ukr_mova_Avramenko.pdf" download>Завантажити підручник</a>
        </li></br>
    </ol>

    <h4>1 клас</h4>
<ol>
    <li><b>Українська мова. Буквар</b><br/>
        Підручник для 1 класу закладів загальної середньої освіти (у 2-х частинах)<br/>
        <i>Автор: М. Захарійчук</i>
          </br>
        <a href="/pdf/books/BUKVAR_1.pdf" download>Завантажити підручник. Частина 1</a>
        </br>
        <a href="/pdf/books/BUKVAR_2.pdf" download>Завантажити підручник. Частина 2</a>
    </li></br>

    <li><b>Математика</b><br/>
        Підручник для 1 класу закладів загальної середньої освіти<br/>
        <i>Автори: Т. Прошкуратова, А. Пархоменко</i>
    </li></br>
</ol>

<h4>2 клас</h4>
<ol>
    <li><b>Українська мова та читання</b><br/>
        Підручник для 2 класу закладів загальної середньої освіти (у 2-х частинах)<br/>
        Частина перша — <i>Автор: М. Захарійчук</i><br/>
        Частина друга — <i>Автори: М. Захарійчук, М. Іванчук</i>
    </li></br>

    <li><b>Математика</b><br/>
        Підручник для 2 класу закладів загальної середньої освіти<br/>
        <i>Автори: Т. Прошкуратова, А. Пархоменко, Л. Пиліпко</i>
    </li></br>
</ol>

<h4>3 клас</h4>
<ol>
    <li><b>Українська мова та читання</b><br/>
        Підручник для 3 класу закладів загальної середньої освіти (у 2-х частинах)<br/>
        Частина перша — <i>Автор: М. Захарійчук</i><br/>
        Частина друга — <i>Автори: М. Захарійчук, М. Іванчук</i>
    </li></br>

    <li><b>Математика</b><br/>
        Підручник для 3 класу закладів загальної середньої освіти (у 2-х частинах)<br/>
        <i>Автори: Т. Прошкуратова, А. Пархоменко, Л. Пиліпко</i>
    </li></br>
</ol>

<div style="text-align: center; margin-top: 20px; font-size: 0.9em; color: #555;">
    © 2025 Усі права захищено. Автори: Ірина Тригуб, Наталія Поліщук, Павло Поліщук, Н.Б. Голуб, О.М. Горошкіна, О.М. Авраменко, М. Захарійчук, М. Іванчук, Т. Прошкуратова, А. Пархоменко, Л. Пиліпко.
</div>

`

import { text_mod_46 } from "../text/Text.js";

export const module46 = {
  title: "Зв’язок прикметників з іменником",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_46,
    },
  ],
};

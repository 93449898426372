import { text_mod_58 } from "../text/Text.js";

export const module58 = {
  title: "Вимова та правопис найуживаніших числових виразів",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_58,
    },
  ],
};

import { text_mod_64, } from "../text/Text.js";
export const module64 = {
  title:
    "Буква є. Звукове значення букви є",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_64,
    },
  ]
};
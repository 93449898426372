const paths = {
  "О. Авраменко": "avr",
  // "Т. Яценко, В. Пахаренко, О. Слижук": "yac",
  Маляр: "mal",
  "Н. Поліщук, П. Поліщук": "pol",
  "Т. Прошкуратова, А. Пархоменко": "prosh",
  "Н. Голуб, О. Горошкіна": "gol",
  "М. Захарійчук": "zah",
  "М. Захарійчук, М. Іванчук": "zahiv",
};

export default paths;

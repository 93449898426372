import { text_mod_98 } from "../text/Text.js";
export const module98 = {

  title: "Урок 98. Нумерація трицифрових чисел. Письмове додавання трицифрових чисел виду 124 + 222, 137 + 256. Розв'язання задач із непрямим збільшенням числа. Письмове віднімання чисел виду 563 – 441. Письмове додавання трьох доданків.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_98,
    },
  ],
};

import { text_mod_64 } from "../text/Text.js";

export const module64 = {
  title: "Написання НЕ з дієсловами",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_64,
    },
  ],
};

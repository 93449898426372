import { text_mod_32 } from "../text/Text.js";

export const module32 = {
  title: "Правопис префіксів роз-, без-. Перенос слів із префіксами. Написання префіксів з-, с-",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_32,
    },
  ],
};


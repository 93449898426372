import { text_mod_69 } from "../text/Text.js";

export const module69 = {
  title: "Уживання дієслів у власному мовленні",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_69,
    },
  ],
};

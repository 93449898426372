import { text_mod_48 } from "../text/Text.js";

export const module48 = {
  title: "Прикметники-антоніми",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_48,
    },
  ],
};

import { text_mod_37 } from "../text/Text.js";

export const module37 = {
  title: "Створюю відгук про прочитаний текст «Подарунок від святого Миколая» (за Дз. Матіяш)",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_37,
    },
  ],
};

import { text_mod_86, } from "../text/Text.js";
export const module86 = {
  title:
    "Літера З",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_86,
    },
  ]
};
export const text_mod_5 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p>
<iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLtUAAAAAAARXIMswwyIJTDM_ewUF8FNM"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;



export const text_mod_6 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLuEAAAAAAAae36VzVkZh1lV61HDavIaE"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_16 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLtkAAAAAAARsLYfqumm1iPvJgG13Q3PE"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_18 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLukAAAAAAARoCvP7Jl2kNhbyWNsQ9Xfg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_23 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLLUEAAAAAAezleKfyCZTPkQP_y_dP8y8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_25 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLLkEAAAAAAVWqiLbZdncw0JYkJCf5gsc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_26 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLLEEAAAAAAZs92OvABfukUuu9jq41r5c" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_30 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLL0EAAAAAAd4Of4oryvpoGARtKJrv8js" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_32 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLMEEAAAAAAXzpvv8noSdUDs2L1DxvxLk" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_34 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLMUEAAAAAAZYa87WwkVGRiwapUogEgrU" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_36 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLM0EAAAAAAVj_bZ2BMcbkx3aNYL4_Thg" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_38 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLMkEAAAAAAUSCWsAinNJmywTwSu62oQE" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_43 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLNEEAAAAAAY0xdVpVimyV8kIdhOjL3Eg" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_46 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLNkEAAAAAAX3V7xdZKy_D1iV36oN2dzA" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_51 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLN0EAAAAAARpC_lnUstwZH4Ly95X-OYA" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_56 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLNUEAAAAAAYj1dF_dOlZv6lrNPHzmX5M" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_60 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLOEEAAAAAAZA-r2pLRq23VCwCTB8Pf3Q" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_68 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLOkEAAAAAAX8v7lWg1P10xN14Zmo0LYc" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_72 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLOUEAAAAAAalV2lNJiK3rhtCCV-ynyaE" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_81 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQaMg29iGRISKjYnkmof8ORARK8fbzUi4mWPrXlXWQMbFA" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_86 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTzEj-ANEc5QIv5bRIbqx8bATW2W9EOKo2v6A3bAhOD5oc" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_91 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTRBMw9msdvQqAeqisnvqibAcaVsicAgXFefhCP5i4uoJU" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_98 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSjWOwJMYmERYgLoeuhoHiSAVfBA2a1dy3C2_de1l2CHE4" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_105 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSAveMy5eeoQ6OxJu_-yOinAc41VNZRnxwLCOfk6dhf30s" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_113 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTj2tOPJqUeTLY5lxyt3kjHAQfgf88nFTzu8mt_KHyY2Js" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_117 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTMn4xgjaUeTas1MiO26RwRAe4yJwuyRbeCpsDO1lmx54Q" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_122 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQw2u4uKVxCSKOmRuGaCzVeAcyXI47yNY_8DOlkt6rLGPk" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_127 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSBZBeeV_gjTa020ELdpD2eAdkF9NKsXkaW97wM34nkIig" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_133 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQ2kFvDSJPOT4k8OaXXdNv_AZlkQoZBPssuvHKCeEG36_A" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_138 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQEREFyw_q8Toh0b6rN4A2rAYD2dnS_GW-yasdTt4d3t-Q" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;



export const text_mod_144 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQQAyNEwy3CSpzco-uBt34cAYLEv92d01-VyG-jKHWeY_0" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_149 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQQAyNEwy3CSpzco-uBt34cAYLEv92d01-VyG-jKHWeY_0" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_155 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQT5v0-y3iVkToBsxZuIb60OAczE3L2bKJv_KQy3QD4pjWI" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_160 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSbfxHQEUmVTLE0P3sFAvxxAdaaw37BM8tjcrbPHQAUgRs" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;



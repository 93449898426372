import { text_mod_67, } from "../text/Text.js";
export const module67 = {
  title:
    "Буквосполучення дж, дз",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_67,
    },
  ]
};